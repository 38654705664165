@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300&family=Montaga&family=Roboto+Slab:wght@700&display=swap');
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-thin.woff2") format("woff2");
     font-weight: 100
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-thin-italic.woff2") format("woff2");
     font-weight: 100;
     font-style: italic
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-light.woff2") format("woff2");
     font-weight: 300
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-light-italic.woff2") format("woff2");
     font-weight: 300;
     font-style: italic
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-regular.woff2") format("woff2");
     font-weight: 400
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-regular-italic.woff2") format("woff2");
     font-weight: 400;
     font-style: italic
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-medium.woff2") format("woff2");
     font-weight: 600
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-medium-italic.woff2") format("woff2");
     font-weight: 600;
     font-style: italic
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-bold.woff2") format("woff2");
     font-weight: 700
 }
 
 @font-face {
     font-family: "Square Market";
     font-display: inline-block;
     src: url("https://d1g145x70srn7h.cloudfront.net/fonts/sqmarket/sqmarket-bold-italic.woff2") format("woff2");
     font-weight: 700;
     font-style: italic
 }
 
 :root{
     --sidebar-flex:1.5;
     --condiv-flex:6;
     --fontsize-p:1.2rem;
     --fontsize-nav:1.7rem;
     --typing-font:3rem;
}
 * {
     margin:0;
     padding: 0;
}
 body{
     font-family: 'Hind Madurai', sans-serif;
}
 .regularApp {
     display: flex;
     flex-direction: column;
     width: 100vw;
     
}
 .headerSection {
     font-family: 'Roboto Slab', serif;
     position: sticky;
     top: 0;
     padding:60px 30px 0px 30px ;
     background: #B7E4C7;
     background: linear-gradient(180deg, #2D6A4F 30%,#B7E4C7 60%);
}
 .footerSection {
     font-family: 'Roboto Slab', serif;
     position: sticky;
     bottom: 0;
     padding: 30px 15px 0px 15px;
     background: #B7E4C7;
     background: linear-gradient(180deg, #B7E4C7 10%,#2D6A4F 40%);
}
 .condiv {
     padding: 100px ;
     background-color: #B7E4C7;
     flex:var(--condiv-flex);
     text-decoration-color: #1B4332;
}
.modalTitle {
  text-decoration-color: #1B4332;
}
.modalcondiv {
  background-color: #B7E4C7;
  flex:var(--condiv-flex);
  text-decoration-color: #1B4332;
}
 .home {
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
}
 .divSpace {
     padding-top: 150px;
}
 .profilepic {
     border-radius: 50%;
     width: 250px;
     margin: 5px;
}
 .describerText {
     font-family: 'Hind Madurai', sans-serif;
     color: #40916C;
}
 .typingeffect {
     font-size: var(--typing-font);
     margin-bottom: 30px;
     font-family: 'Roboto Slab', serif;
     color: #40916C;
}
 .resumeLink {
     text-decoration: none;
     color: #1B4332;
     font-size: 20px;
}
 .headerSection ul{
     font-size:var(--fontsize-nav) ;
     text-align: center;
     list-style-type: none;
     text-decoration: none;
}
 .headerSection ul li {
     display: inline;
     margin:40px;
}
 .headerSection a {
     color: #1B4332;
}
 .headerSection a:hover {
     color: #52B788;
}
 .footerSection ul{
     font-size:var(--fontsize-nav) ;
     text-align: center;
     list-style-type: none;
     text-decoration: none;
     color: #52B788 !important;
}
 .footerSection ul li {
     display: inline;
     margin:5px;
}
 .footerSection a:hover {
     color: #1B4332 
}
 .footerSection a {
     text-decoration: none;
     color: #52B788;
}
.footerEmail {
     color: #52B788;
     padding-left: 5px;
     padding-right: 5px; 
}
 .flagButton {
     padding: 0;
     border: none;
     background: none;
     text-decoration: none;
     color: #52B788;
}
.experienceButton {
  padding: 0;
  border: none;
  background: none;
  text-align:left;
  width: 100%;
}

.btn-primary {
  background-color: #1B4332;
}

 .downButton {
     padding: 0;
     border: none;
     background: none;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     text-decoration: none;
     color: #52B788;
}
 .downButton:hover {
     color: #1B4332;
}
 .navLink {
     text-decoration: none;
     color: #1B4332;
}
 .navLink:hover {
     text-decoration: none;
     color: #52B788;
}
 .subtopic {
     margin:10px 10px 30px 10px !important;
}
 p{
     font-size: var(--fontsize-p);
     color:#1B4332;
}
 h1,h2,h3,h4,p{
     margin: 10px;
     color:#1B4332;
}
 .widecard {
     border:0px solid #8D7471;
     display: flex;
     margin:30px 0px 30px 0px;
}
 .widecard:hover {
     color: #1B4332;
     background-color: #74C69D;
     box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
/* Skills Page CSS */
 .skills ul li {
     list-style-type:none;
     margin:30px;
}
 .skillsContainer {
     width: 100%;
    /* Full width */
}
.otherSkillsDiv {
  background-color: #081C15;
  padding-top: 10px;
  padding-bottom: 5px;
}
  .otherSkillsDiv ul li{
    font-size: var(--fontsize-p);
    display: inline;
     margin:5px;
     color: #B7E4C7;
     font-family: 'Hind Madurai', sans-serif;
  }
 .skills {
     padding-top: 10px;
    /* Add top padding */
     padding-bottom: 10px;
    /* Add bottom padding */
}
 .java {
    width: 90%;
     background-color: #081C15;
}
/* Green */
 .javascript {
    width: 80%;
     background-color: #081C15;
}
/* Dark Grey */
 .html {
    width: 90%;
     background-color: #081C15;
}
/* Blue */
 .css {
    width: 65%;
     background-color: #2D6A4F;
}
/* Red */
 .sql {
    width: 90%;
     background-color: #081C15;
}
/* Green */
 .react {
    width: 70%;
     background-color: #2D6A4F;
}
/* Blue */
 .angular {
    width: 65%;
     background-color: #081C15;
}
/* Red */
 .node {
    width: 50%;
     background-color: #52B788;
}
/* Dark Grey */
 .handlebars {
    width: 70%;
     background-color: #2D6A4F;
}
/* Blue */
 .jira {
    width: 50%;
     background-color: #52B788;
}
/* Red */
 .git {
    width: 65%;
     background-color: #52B788;
}
/* Green */
 .spring {
    width: 85%;
     background-color: #081C15;
}
/* Green */
.rest {
  width: 75%;
   background-color: #081C15;
}
/* Blue */
 .jpa {
    width: 70%;
     background-color: #2D6A4F;
}
/* Red */
 .hibernate {
    width: 65%;
     background-color: #2D6A4F;
}
/* Dark Grey */
 .ajax {
    width: 90%;
     background-color: #081C15;
}
/* Green */
 .soapui {
    width: 50%;
     background-color: #52B788;
}
/* Blue */
 .xml {
    width: 75%;
     background-color: #2D6A4F;
}
/* Dark Grey */
 .ios {
    width: 70%;
     background-color: #2D6A4F;
}
/* Green */
 .web {
    width: 90%;
     background-color: #081C15;
}
.rqs {
    font-family: "Square Market", Helvetica, Arial;
    align-content: center;
    padding-top: 0px;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.rqs h2 {
    padding-top: 20px;
}

.rqs button {
    text-size-adjust: 100%;
    letter-spacing: -0.2px;
    box-sizing: border-box;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    appearance: none;
    display: inline-block;
    position: relative;
    max-width: 100%;
    min-height: 50px;
    padding: 13px 20px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-weight: 600;
    font-family: "Square Market", Helvetica, Arial, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.6, 1);
    border-color: transparent;
    color: white;
    background-color: #006aff;
}

.rqsSubmit {
    text-size-adjust: 100%;
    letter-spacing: -0.2px;
    box-sizing: border-box;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    appearance: none;
    display: inline-block;
    position: relative;
    max-width: 100%;
    min-height: 50px;
    padding: 13px 20px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-weight: 600;
    font-family: "Square Market", Helvetica, Arial, "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.6, 1);
    border-color: transparent;
    white-space: nowrap;
    color: white;
    background-color: #006aff;
}

.rqsForm {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    width: 100%
}



.rqsDiv {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color:white;
}

.rqsInverseDiv {
     padding-top: 30px;
     padding-bottom: 30px;
     background-color:black;
     color: white !important;
     text-decoration-color: white !important;
}

.rqsMenuInverseDiv {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color:black;
    color: black !important;
    text-decoration-color: black !important;
}

.rqsInputContainer {
     padding-left:40%;
     padding-right:20px;
     align-content: center;
    text-align: center;
     width: 60%;
}

.dayNameLabel {
    padding-right: 10px;
}

.rqsInput {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.rqsHoursTo {
    padding-left: 10px;
}

.secondHourPadding {
    padding-left: 5%;
}

.rqs tr {
    padding-right: 5%;    
}

.themeColorSpan {
    display: inline-block;
    margin: 20px;
}

.rqsTextInput {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    line-height: normal;
    display: inline-block;
    border: 1px solid #e0e2e3;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    box-shadow: none;
    height: 41px;
    width: 90%;
    background: white;
    border-radius: 3px 3px 0 0;
}

.nameSpan {
    display: inline-block;
    margin: 0px;
}

.contactPreferenceSpan {
    display: inline-block;
    margin: 10px;
}

.contactPreferenceSpan label{
    padding-left: 10px;
}

.phoneNumberSpan {
    display: inline-block;
    margin: 0px;
}

.rqsNumberInput {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    line-height: normal;
    display: inline-block;
    border: 1px solid #e0e2e3;
    padding-left: 0px;
    padding-right: 0px;
    outline: none;
    box-shadow: none;
    height: 41px;
    width: 80%;
    background: white;
    border-radius: 3px 3px 0 0;
}

.yesNoSpan {
    display: inline-block;
    margin: 10px;
}

.yesNoSpan label{
    padding-left: 5px;
}

.staffTableContainer {
    align-content: right; 
    text-align: right;
    padding-left: 33%;
}

.rqsTable {
    width: 80%;
}

.rqs table {
    -webkit-text-size-adjust: 100%;
    visibility: inherit;
    --onetrust-bg-color: #FFFF;
    --onetrust-button-outline-color: rgba(0, 106, 255, 0.5);
    --onetrust-focus-outline-color: rgba(0, 106, 255, 0.5);
    --onetrust-primary-text-color: rgba(0, 0, 0, 0.9);
    --onetrust-secondary-text-color: rgba(0, 0, 0, 0.9);
    --onetrust-primary-button-bg-color: #006AFF;
    --onetrust-primary-button-hover-color: #005FE5;
    --onetrust-primary-button-pressed-color: #0055CC;
    --onetrust-primary-button-text-color: #FFFF;
    --onetrust-primary-button-focus-shadow: 0 0 0 2px var(--onetrust-button-outline-color);
    --onetrust-secondary-button-bg-color: rgba(0, 0, 0, 0.05);
    --onetrust-secondary-button-hover-color: #E5F0FF;
    --onetrust-secondary-button-pressed-color: #CCE1FF;
    --onetrust-secondary-button-text-color: #005AD9;
    --onetrust-secondary-button-focus-shadow: 0 0 0 2px var(--onetrust-button-outline-color);
    --onetrust-close-icon: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.662 1.97062L6.63205 5.00088L9.662 8.031C10.1127 8.48186 10.1127 9.2122 9.662 9.66306C9.43681 9.88825 9.14158 10.0009 8.84649 10.0009C8.55091 10.0009 8.25564 9.88842 8.03063 9.66306L5.00002 6.6326L1.96965 9.66303C1.74449 9.88822 1.44922 10.0009 1.15387 10.0009C0.858611 10.0009 0.563548 9.88839 0.338189 9.66303C-0.1125 9.21237 -0.1125 8.48201 0.338189 8.03097L3.36805 5.00085L0.338017 1.97062C-0.112672 1.51993 -0.112672 0.789425 0.338017 0.338735C0.78862 -0.11161 1.5187 -0.11161 1.96947 0.338735L4.99999 3.369L8.03028 0.338735C8.48114 -0.11161 9.21131 -0.11161 9.66183 0.338735C10.1127 0.789425 10.1127 1.51993 9.662 1.97062Z' fill='%23565656'/%3E%3C/svg%3E%0A");
    --onetrust-hyperlink-color: #005AD9;
    --onetrust-hyperlink-outline-color: var(--onetrust-focus-outline-color);
    --onetrust-hyperlink-outline: 2px solid var(--onetrust-hyperlink-outline-color);
    --onetrust-toggle-on-color: #006AFF;
    --onetrust-toggle-on-knob-color: #FFFF;
    --onetrust-toggle-on-bg-color: var(--onetrust-toggle-on-color);
    --onetrust-toggle-on-border-color: var(--onetrust-toggle-on-color);
    --onetrust-toggle-on-hover-color: #005FE5;
    --onetrust-toggle-on-hover-border-color: var(--onetrust-toggle-on-hover-color);
    --onetrust-toggle-on-hover-bg-color: var(--onetrust-toggle-on-hover-color);
    --onetust-toggle-on-pressed-color: #0055CC;
    --onetrust-toggle-on-pressed-border-color: var(--onetust-toggle-on-pressed-color);
    --onetrust-toggle-on-pressed-bg-color: var(--onetust-toggle-on-pressed-color);
    --onetrust-toggle-off-color: rgba(0, 0, 0, 0.3);
    --onetrust-toggle-off-bg-color: #FFFF;
    --onetrust-toggle-off-knob-color: var(--onetrust-toggle-off-color);
    --onetrust-toggle-off-border-color: var(--onetrust-toggle-off-color);
    --onetrust-toggle-off-hover-color: #006AFF;
    --onetrust-toggle-off-hover-border-color: var(--onetrust-toggle-off-hover-color);
    --onetrust-toggle-off-hover-knob-color: var(--onetrust-toggle-off-hover-color);
    --onetrust-toggle-off-pressed-color: #0055CC;
    --onetrust-toggle-off-pressed-border-color: var(--onetrust-toggle-off-pressed-color);
    --onetrust-toggle-off-pressed-knob-color: var(--onetrust-toggle-off-pressed-color);
    --onetrust-accordian-hover-color: #005AD9;
    --onetrust-accordian-bg-color: var(--onetrust-bg-color);
    --onetrust-accordian-divider-color: rgba(0, 0, 0, 0.05);
    --onetrust-accordian-button-focus-outline: 1px solid #0055CC;
    --onetrust-layout-border-color: #e9e9e9;
    --onetrust-font-family: "Square Market", "Helvetica Neue", "Helvetica", sans-serif;
    --core-base-size: var(--core-small-layout-size-base-size);
    --core-blue-10-color: var(--core-blue-10-light-mode-color);
    --core-blue-10-dark-mode-color: #0055CC;
    --core-blue-10-light-mode-color: #0055CC;
    --core-blue-20-color: var(--core-blue-20-light-mode-color);
    --core-blue-20-dark-mode-color: #005FE5;
    --core-blue-20-light-mode-color: #005FE6;
    --core-blue-30-color: var(--core-blue-30-light-mode-color);
    --core-blue-30-dark-mode-color: #002559;
    --core-blue-30-light-mode-color: #CCE1FF;
    --core-blue-40-color: var(--core-blue-40-light-mode-color);
    --core-blue-40-dark-mode-color: #001533;
    --core-blue-40-light-mode-color: #E6F0FF;
    --core-blue-fill-color: var(--core-blue-fill-light-mode-color);
    --core-blue-fill-dark-mode-color: #006AFF;
    --core-blue-fill-light-mode-color: #006AFF;
    --core-blue-text-color: var(--core-blue-text-light-mode-color);
    --core-blue-text-dark-mode-color: #4A95FF;
    --core-blue-text-light-mode-color: #005AD9;
    --core-breakpoint-extra-wide-min-width: 1024px;
    --core-breakpoint-medium-max-width: 839px;
    --core-breakpoint-medium-min-width: 600px;
    --core-breakpoint-narrow-max-width: 599px;
    --core-breakpoint-narrow-min-width: 0px;
    --core-breakpoint-wide-min-max-width: 1023px;
    --core-breakpoint-wide-min-width: 800px;
    --core-brown-10-color: var(--core-brown-10-light-mode-color);
    --core-brown-10-dark-mode-color: #33261A;
    --core-brown-10-light-mode-color: #332517;
    --core-brown-20-color: var(--core-brown-20-light-mode-color);
    --core-brown-20-dark-mode-color: #4D3926;
    --core-brown-20-light-mode-color: #4D3722;
    --core-brown-30-color: var(--core-brown-30-light-mode-color);
    --core-brown-30-dark-mode-color: #592D00;
    --core-brown-30-light-mode-color: #FFE6CC;
    --core-brown-40-color: var(--core-brown-40-light-mode-color);
    --core-brown-40-dark-mode-color: #331900;
    --core-brown-40-light-mode-color: #FFF2E6;
    --core-brown-fill-color: var(--core-brown-fill-light-mode-color);
    --core-brown-fill-dark-mode-color: #664D33;
    --core-brown-fill-light-mode-color: #664A2E;
    --core-brown-text-color: var(--core-brown-text-light-mode-color);
    --core-brown-text-dark-mode-color: #C99866;
    --core-brown-text-light-mode-color: #664A2E;
    --core-burgundy-10-color: var(--core-burgundy-10-light-mode-color);
    --core-burgundy-10-dark-mode-color: #660525;
    --core-burgundy-10-light-mode-color: #660525;
    --core-burgundy-20-color: var(--core-burgundy-20-light-mode-color);
    --core-burgundy-20-dark-mode-color: #80062F;
    --core-burgundy-20-light-mode-color: #80062F;
    --core-burgundy-30-color: var(--core-burgundy-30-light-mode-color);
    --core-burgundy-30-dark-mode-color: #59001E;
    --core-burgundy-30-light-mode-color: #FFCCDD;
    --core-burgundy-40-color: var(--core-burgundy-40-light-mode-color);
    --core-burgundy-40-dark-mode-color: #330011;
    --core-burgundy-40-light-mode-color: #FFE6EE;
    --core-burgundy-fill-color: var(--core-burgundy-fill-light-mode-color);
    --core-burgundy-fill-dark-mode-color: #990838;
    --core-burgundy-fill-light-mode-color: #990838;
    --core-burgundy-text-color: var(--core-burgundy-text-light-mode-color);
    --core-burgundy-text-dark-mode-color: #FF568E;
    --core-burgundy-text-light-mode-color: #990838;
    --core-critical-10-color: var(--core-critical-10-light-mode-color);
    --core-critical-10-dark-mode-color: var(--core-red-10-dark-mode-color);
    --core-critical-10-light-mode-color: var(--core-red-10-light-mode-color);
    --core-critical-20-color: var(--core-critical-20-light-mode-color);
    --core-critical-20-dark-mode-color: var(--core-red-20-dark-mode-color);
    --core-critical-20-light-mode-color: var(--core-red-20-light-mode-color);
    --core-critical-30-color: var(--core-critical-30-light-mode-color);
    --core-critical-30-dark-mode-color: var(--core-red-30-dark-mode-color);
    --core-critical-30-light-mode-color: var(--core-red-30-light-mode-color);
    --core-critical-40-color: var(--core-critical-40-light-mode-color);
    --core-critical-40-dark-mode-color: var(--core-red-40-dark-mode-color);
    --core-critical-40-light-mode-color: var(--core-red-40-light-mode-color);
    --core-critical-fill-color: var(--core-critical-fill-light-mode-color);
    --core-critical-fill-dark-mode-color: var(--core-red-fill-dark-mode-color);
    --core-critical-fill-light-mode-color: var(--core-red-fill-light-mode-color);
    --core-critical-text-color: var(--core-critical-text-light-mode-color);
    --core-critical-text-dark-mode-color: var(--core-red-text-dark-mode-color);
    --core-critical-text-light-mode-color: var(--core-red-text-light-mode-color);
    --core-divider-10-color: var(--core-divider-10-light-mode-color);
    --core-divider-10-dark-mode-color: rgba(255,255,255,0.3);
    --core-divider-10-light-mode-color: rgba(0,0,0,0.3);
    --core-divider-20-color: var(--core-divider-20-light-mode-color);
    --core-divider-20-dark-mode-color: rgba(255,255,255,0.08);
    --core-divider-20-light-mode-color: rgba(0,0,0,0.05);
    --core-emphasis-10-color: var(--core-emphasis-10-light-mode-color);
    --core-emphasis-10-dark-mode-color: var(--core-blue-10-dark-mode-color);
    --core-emphasis-10-light-mode-color: var(--core-blue-10-light-mode-color);
    --core-emphasis-20-color: var(--core-emphasis-20-light-mode-color);
    --core-emphasis-20-dark-mode-color: var(--core-blue-20-dark-mode-color);
    --core-emphasis-20-light-mode-color: var(--core-blue-20-light-mode-color);
    --core-emphasis-30-color: var(--core-emphasis-30-light-mode-color);
    --core-emphasis-30-dark-mode-color: var(--core-blue-30-dark-mode-color);
    --core-emphasis-30-light-mode-color: var(--core-blue-30-light-mode-color);
    --core-emphasis-40-color: var(--core-emphasis-40-light-mode-color);
    --core-emphasis-40-dark-mode-color: var(--core-blue-40-dark-mode-color);
    --core-emphasis-40-light-mode-color: var(--core-blue-40-light-mode-color);
    --core-emphasis-fill-color: var(--core-emphasis-fill-light-mode-color);
    --core-emphasis-fill-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --core-emphasis-fill-light-mode-color: var(--core-blue-fill-light-mode-color);
    --core-emphasis-text-color: var(--core-emphasis-text-light-mode-color);
    --core-emphasis-text-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --core-emphasis-text-light-mode-color: var(--core-blue-text-light-mode-color);
    --core-extra-wide-viewport-grid-column-count: 4;
    --core-extra-wide-viewport-grid-horizontal-spacing: var(--core-metrics-spacing-200);
    --core-extra-wide-viewport-grid-item-full-size-column-span: 4;
    --core-extra-wide-viewport-grid-item-large-size-column-span: 3;
    --core-extra-wide-viewport-grid-item-medium-size-column-span: 2;
    --core-extra-wide-viewport-grid-item-small-size-column-span: 1;
    --core-extra-wide-viewport-grid-vertical-spacing: var(--core-metrics-spacing-200);
    --core-fill-10-color: var(--core-fill-10-light-mode-color);
    --core-fill-10-dark-mode-color: rgba(255,255,255,0.95);
    --core-fill-10-light-mode-color: rgba(0,0,0,0.9);
    --core-fill-20-color: var(--core-fill-20-light-mode-color);
    --core-fill-20-dark-mode-color: rgba(255,255,255,0.3);
    --core-fill-20-light-mode-color: rgba(0,0,0,0.3);
    --core-fill-30-color: var(--core-fill-30-light-mode-color);
    --core-fill-30-dark-mode-color: rgba(255,255,255,0.15);
    --core-fill-30-light-mode-color: rgba(0,0,0,0.15);
    --core-fill-40-color: var(--core-fill-40-light-mode-color);
    --core-fill-40-dark-mode-color: rgba(255,255,255,0.08);
    --core-fill-40-light-mode-color: rgba(0,0,0,0.05);
    --core-fill-50-color: var(--core-fill-50-light-mode-color);
    --core-fill-50-dark-mode-color: rgba(255,255,255,0.05);
    --core-fill-50-light-mode-color: rgba(0,0,0,0.03);
    --core-fill-black-color: var(--core-fill-black-light-mode-color);
    --core-fill-black-dark-mode-color: rgba(0,0,0,0.9);
    --core-fill-black-light-mode-color: rgba(0,0,0,0.9);
    --core-fill-inverse-color: var(--core-fill-inverse-light-mode-color);
    --core-fill-inverse-dark-mode-color: rgba(0,0,0,0.9);
    --core-fill-inverse-light-mode-color: rgba(0,0,0,0.05);
    --core-fill-white-color: var(--core-fill-white-light-mode-color);
    --core-fill-white-dark-mode-color: rgba(255,255,255,1);
    --core-fill-white-light-mode-color: rgba(255,255,255,1);
    --core-focus-color: var(--core-focus-light-mode-color);
    --core-focus-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --core-focus-light-mode-color: var(--core-blue-fill-light-mode-color);
    --core-focus-ring-border-size: 2px;
    --core-focus-ring-buffer-size: 2px;
    --core-focus-ring-color: var(--core-focus-ring-light-mode-color);
    --core-focus-ring-dark-mode-color: var(--core-focus-dark-mode-color);
    --core-focus-ring-light-mode-color: var(--core-focus-light-mode-color);
    --core-forest-10-color: var(--core-forest-10-light-mode-color);
    --core-forest-10-dark-mode-color: #0F4D19;
    --core-forest-10-light-mode-color: #0F4D19;
    --core-forest-20-color: var(--core-forest-20-light-mode-color);
    --core-forest-20-dark-mode-color: #146622;
    --core-forest-20-light-mode-color: #146622;
    --core-forest-30-color: var(--core-forest-30-light-mode-color);
    --core-forest-30-dark-mode-color: #00590F;
    --core-forest-30-light-mode-color: #CCFFD5;
    --core-forest-40-color: var(--core-forest-40-light-mode-color);
    --core-forest-40-dark-mode-color: #003308;
    --core-forest-40-light-mode-color: #E6FFEA;
    --core-forest-fill-color: var(--core-forest-fill-light-mode-color);
    --core-forest-fill-dark-mode-color: #19802A;
    --core-forest-fill-light-mode-color: #19802A;
    --core-forest-text-color: var(--core-forest-text-light-mode-color);
    --core-forest-text-dark-mode-color: #16D956;
    --core-forest-text-light-mode-color: #007D2A;
    --core-gold-10-color: var(--core-gold-10-light-mode-color);
    --core-gold-10-dark-mode-color: #CC8033;
    --core-gold-10-light-mode-color: #CC8033;
    --core-gold-20-color: var(--core-gold-20-light-mode-color);
    --core-gold-20-dark-mode-color: #E68F39;
    --core-gold-20-light-mode-color: #E68F39;
    --core-gold-30-color: var(--core-gold-30-light-mode-color);
    --core-gold-30-dark-mode-color: #592D00;
    --core-gold-30-light-mode-color: #FFE6CC;
    --core-gold-40-color: var(--core-gold-40-light-mode-color);
    --core-gold-40-dark-mode-color: #331A00;
    --core-gold-40-light-mode-color: #FFF2E6;
    --core-gold-fill-color: var(--core-gold-fill-light-mode-color);
    --core-gold-fill-dark-mode-color: #FF9F40;
    --core-gold-fill-light-mode-color: #FF9F40;
    --core-gold-text-color: var(--core-gold-text-light-mode-color);
    --core-gold-text-dark-mode-color: #FF9F40;
    --core-gold-text-light-mode-color: #945C25;
    --core-green-10-color: var(--core-green-10-light-mode-color);
    --core-green-10-dark-mode-color: #00802A;
    --core-green-10-light-mode-color: #00802A;
    --core-green-20-color: var(--core-green-20-light-mode-color);
    --core-green-20-dark-mode-color: #009933;
    --core-green-20-light-mode-color: #009933;
    --core-green-30-color: var(--core-green-30-light-mode-color);
    --core-green-30-dark-mode-color: #00591E;
    --core-green-30-light-mode-color: #CCFFDD;
    --core-green-40-color: var(--core-green-40-light-mode-color);
    --core-green-40-dark-mode-color: #003311;
    --core-green-40-light-mode-color: #E5FFEE;
    --core-green-fill-color: var(--core-green-fill-light-mode-color);
    --core-green-fill-dark-mode-color: #00B33B;
    --core-green-fill-light-mode-color: #00B23B;
    --core-green-text-color: var(--core-green-text-light-mode-color);
    --core-green-text-dark-mode-color: #16D956;
    --core-green-text-light-mode-color: #007D2A;
    --core-large-layout-size-base-size: 16px;
    --core-large-layout-size-space-10-size: var(--core-large-layout-size-base-size);
    --core-large-layout-size-text-size: 24px;
    --core-medium-layout-size-base-size: 8px;
    --core-medium-layout-size-space-10-size: var(--core-medium-layout-size-base-size);
    --core-medium-layout-size-text-size: 18px;
    --core-medium-viewport-grid-column-count: 2;
    --core-medium-viewport-grid-horizontal-spacing: var(--core-wide-viewport-grid-horizontal-spacing);
    --core-medium-viewport-grid-item-full-size-column-span: 2;
    --core-medium-viewport-grid-item-large-size-column-span: 1;
    --core-medium-viewport-grid-item-medium-size-column-span: 1;
    --core-medium-viewport-grid-item-small-size-column-span: 1;
    --core-medium-viewport-grid-vertical-spacing: var(--core-wide-viewport-grid-vertical-spacing);
    --core-metrics-spacing-100: 8px;
    --core-metrics-spacing-150: 12px;
    --core-metrics-spacing-200: 16px;
    --core-metrics-spacing-25: 2px;
    --core-metrics-spacing-300: 24px;
    --core-metrics-spacing-400: 32px;
    --core-metrics-spacing-500: 40px;
    --core-narrow-viewport-grid-column-count: 1;
    --core-narrow-viewport-grid-horizontal-spacing: var(--core-medium-viewport-grid-horizontal-spacing);
    --core-narrow-viewport-grid-item-full-size-column-span: 1;
    --core-narrow-viewport-grid-item-large-size-column-span: 1;
    --core-narrow-viewport-grid-item-medium-size-column-span: 1;
    --core-narrow-viewport-grid-item-small-size-column-span: 1;
    --core-narrow-viewport-grid-vertical-spacing: var(--core-medium-viewport-grid-vertical-spacing);
    --core-orange-10-color: var(--core-orange-10-light-mode-color);
    --core-orange-10-dark-mode-color: #BF4830;
    --core-orange-10-light-mode-color: #BF4830;
    --core-orange-20-color: var(--core-orange-20-light-mode-color);
    --core-orange-20-dark-mode-color: #DB5237;
    --core-orange-20-light-mode-color: #DB5237;
    --core-orange-30-color: var(--core-orange-30-light-mode-color);
    --core-orange-30-dark-mode-color: #590F00;
    --core-orange-30-light-mode-color: #FFD5CC;
    --core-orange-40-color: var(--core-orange-40-light-mode-color);
    --core-orange-40-dark-mode-color: #330800;
    --core-orange-40-light-mode-color: #FFEAE6;
    --core-orange-fill-color: var(--core-orange-fill-light-mode-color);
    --core-orange-fill-dark-mode-color: #F25B3D;
    --core-orange-fill-light-mode-color: #F25B3D;
    --core-orange-text-color: var(--core-orange-text-light-mode-color);
    --core-orange-text-dark-mode-color: #FF5F3F;
    --core-orange-text-light-mode-color: #A83F2A;
    --core-pink-10-color: var(--core-pink-10-light-mode-color);
    --core-pink-10-dark-mode-color: #A62987;
    --core-pink-10-light-mode-color: #A62987;
    --core-pink-20-color: var(--core-pink-20-light-mode-color);
    --core-pink-20-dark-mode-color: #BF309B;
    --core-pink-20-light-mode-color: #BF309B;
    --core-pink-30-color: var(--core-pink-30-light-mode-color);
    --core-pink-30-dark-mode-color: #590043;
    --core-pink-30-light-mode-color: #FFCCF2;
    --core-pink-40-color: var(--core-pink-40-light-mode-color);
    --core-pink-40-dark-mode-color: #330026;
    --core-pink-40-light-mode-color: #FFE6F9;
    --core-pink-fill-color: var(--core-pink-fill-light-mode-color);
    --core-pink-fill-dark-mode-color: #D936B0;
    --core-pink-fill-light-mode-color: #D936B0;
    --core-pink-text-color: var(--core-pink-text-light-mode-color);
    --core-pink-text-dark-mode-color: #FF43D0;
    --core-pink-text-light-mode-color: #A82A88;
    --core-purple-10-color: var(--core-purple-10-light-mode-color);
    --core-purple-10-dark-mode-color: #6811A6;
    --core-purple-10-light-mode-color: #6811A6;
    --core-purple-20-color: var(--core-purple-20-light-mode-color);
    --core-purple-20-dark-mode-color: #7813BF;
    --core-purple-20-light-mode-color: #7813BF;
    --core-purple-30-color: var(--core-purple-30-light-mode-color);
    --core-purple-30-dark-mode-color: #340059;
    --core-purple-30-light-mode-color: #EACCFF;
    --core-purple-40-color: var(--core-purple-40-light-mode-color);
    --core-purple-40-dark-mode-color: #1E0033;
    --core-purple-40-light-mode-color: #F4E6FF;
    --core-purple-fill-color: var(--core-purple-fill-light-mode-color);
    --core-purple-fill-dark-mode-color: #8716D9;
    --core-purple-fill-light-mode-color: #8716D9;
    --core-purple-text-color: var(--core-purple-text-light-mode-color);
    --core-purple-text-dark-mode-color: #C26EFF;
    --core-purple-text-light-mode-color: #8716D9;
    --core-red-10-color: var(--core-red-10-light-mode-color);
    --core-red-10-dark-mode-color: #99001A;
    --core-red-10-light-mode-color: #99001A;
    --core-red-20-color: var(--core-red-20-light-mode-color);
    --core-red-20-dark-mode-color: #B2001E;
    --core-red-20-light-mode-color: #B2001E;
    --core-red-30-color: var(--core-red-30-light-mode-color);
    --core-red-30-dark-mode-color: #59000F;
    --core-red-30-light-mode-color: #FFCCD5;
    --core-red-40-color: var(--core-red-40-light-mode-color);
    --core-red-40-dark-mode-color: #330009;
    --core-red-40-light-mode-color: #FFE5EA;
    --core-red-fill-color: var(--core-red-fill-light-mode-color);
    --core-red-fill-dark-mode-color: #CC0023;
    --core-red-fill-light-mode-color: #CC0023;
    --core-red-text-color: var(--core-red-text-light-mode-color);
    --core-red-text-dark-mode-color: #FF5A76;
    --core-red-text-light-mode-color: #BF0020;
    --core-sky-10-color: var(--core-sky-10-light-mode-color);
    --core-sky-10-dark-mode-color: #1F75CC;
    --core-sky-10-light-mode-color: #1F75CC;
    --core-sky-20-color: var(--core-sky-20-light-mode-color);
    --core-sky-20-dark-mode-color: #2284E6;
    --core-sky-20-light-mode-color: #2284E6;
    --core-sky-30-color: var(--core-sky-30-light-mode-color);
    --core-sky-30-dark-mode-color: #002D59;
    --core-sky-30-light-mode-color: #CCE6FF;
    --core-sky-40-color: var(--core-sky-40-light-mode-color);
    --core-sky-40-dark-mode-color: #001A33;
    --core-sky-40-light-mode-color: #E6F2FF;
    --core-sky-fill-color: var(--core-sky-fill-light-mode-color);
    --core-sky-fill-dark-mode-color: #2693FF;
    --core-sky-fill-light-mode-color: #2693FF;
    --core-sky-text-color: var(--core-sky-text-light-mode-color);
    --core-sky-text-dark-mode-color: #2E97FF;
    --core-sky-text-light-mode-color: #0F65BA;
    --core-small-layout-size-base-size: 8px;
    --core-small-layout-size-space-10-size: var(--core-small-layout-size-base-size);
    --core-small-layout-size-text-size: 16px;
    --core-success-10-color: var(--core-success-10-light-mode-color);
    --core-success-10-dark-mode-color: var(--core-green-10-dark-mode-color);
    --core-success-10-light-mode-color: var(--core-green-10-light-mode-color);
    --core-success-20-color: var(--core-success-20-light-mode-color);
    --core-success-20-dark-mode-color: var(--core-green-20-dark-mode-color);
    --core-success-20-light-mode-color: var(--core-green-20-light-mode-color);
    --core-success-30-color: var(--core-success-30-light-mode-color);
    --core-success-30-dark-mode-color: var(--core-green-30-dark-mode-color);
    --core-success-30-light-mode-color: var(--core-green-30-light-mode-color);
    --core-success-40-color: var(--core-success-40-light-mode-color);
    --core-success-40-dark-mode-color: var(--core-green-40-dark-mode-color);
    --core-success-40-light-mode-color: var(--core-green-40-light-mode-color);
    --core-success-fill-color: var(--core-success-fill-light-mode-color);
    --core-success-fill-dark-mode-color: var(--core-green-fill-dark-mode-color);
    --core-success-fill-light-mode-color: var(--core-green-fill-light-mode-color);
    --core-success-text-color: var(--core-success-text-light-mode-color);
    --core-success-text-dark-mode-color: var(--core-green-text-dark-mode-color);
    --core-success-text-light-mode-color: var(--core-green-text-light-mode-color);
    --core-surface-10-color: var(--core-surface-10-light-mode-color);
    --core-surface-10-dark-mode-color: rgba(20,20,20,1);
    --core-surface-10-light-mode-color: rgba(255,255,255,1);
    --core-surface-20-color: var(--core-surface-20-light-mode-color);
    --core-surface-20-dark-mode-color: rgba(28,28,28,1);
    --core-surface-20-light-mode-color: rgba(255,255,255,1);
    --core-surface-30-color: var(--core-surface-30-light-mode-color);
    --core-surface-30-dark-mode-color: rgba(45,45,45,1);
    --core-surface-30-light-mode-color: rgba(255,255,255,1);
    --core-surface-5-color: var(--core-surface-5-light-mode-color);
    --core-surface-5-dark-mode-color: rgba(8,8,8,1);
    --core-surface-5-light-mode-color: rgba(255,255,255,1);
    --core-surface-inverse-color: var(--core-surface-inverse-light-mode-color);
    --core-surface-inverse-dark-mode-color: rgba(255,255,255,1);
    --core-surface-inverse-light-mode-color: rgba(26,26,26,1);
    --core-surface-overlay-color: var(--core-surface-overlay-light-mode-color);
    --core-surface-overlay-dark-mode-color: rgba(0,0,0,0.8);
    --core-surface-overlay-light-mode-color: rgba(0,0,0,0.8);
    --core-taupe-10-color: var(--core-taupe-10-light-mode-color);
    --core-taupe-10-dark-mode-color: #735639;
    --core-taupe-10-light-mode-color: #735639;
    --core-taupe-20-color: var(--core-taupe-20-light-mode-color);
    --core-taupe-20-dark-mode-color: #8C6946;
    --core-taupe-20-light-mode-color: #8C6946;
    --core-taupe-30-color: var(--core-taupe-30-light-mode-color);
    --core-taupe-30-dark-mode-color: #592D00;
    --core-taupe-30-light-mode-color: #FFE6CC;
    --core-taupe-40-color: var(--core-taupe-40-light-mode-color);
    --core-taupe-40-dark-mode-color: #331A00;
    --core-taupe-40-light-mode-color: #FFF2E6;
    --core-taupe-fill-color: var(--core-taupe-fill-light-mode-color);
    --core-taupe-fill-dark-mode-color: #A67C53;
    --core-taupe-fill-light-mode-color: #A67C53;
    --core-taupe-text-color: var(--core-taupe-text-light-mode-color);
    --core-taupe-text-dark-mode-color: #C99866;
    --core-taupe-text-light-mode-color: #826141;
    --core-teal-10-color: var(--core-teal-10-light-mode-color);
    --core-teal-10-dark-mode-color: #0D8C6D;
    --core-teal-10-light-mode-color: #0D8C6D;
    --core-teal-20-color: var(--core-teal-20-light-mode-color);
    --core-teal-20-dark-mode-color: #10A680;
    --core-teal-20-light-mode-color: #10A680;
    --core-teal-30-color: var(--core-teal-30-light-mode-color);
    --core-teal-30-dark-mode-color: #005943;
    --core-teal-30-light-mode-color: #CCFFF2;
    --core-teal-40-color: var(--core-teal-40-light-mode-color);
    --core-teal-40-dark-mode-color: #003326;
    --core-teal-40-light-mode-color: #E6FFF9;
    --core-teal-fill-color: var(--core-teal-fill-light-mode-color);
    --core-teal-fill-dark-mode-color: #12BF94;
    --core-teal-fill-light-mode-color: #12BF94;
    --core-teal-text-color: var(--core-teal-text-light-mode-color);
    --core-teal-text-dark-mode-color: #16D8A7;
    --core-teal-text-light-mode-color: #0C785D;
    --core-text-10-color: var(--core-text-10-light-mode-color);
    --core-text-10-dark-mode-color: rgba(255,255,255,0.95);
    --core-text-10-light-mode-color: rgba(0,0,0,0.9);
    --core-text-20-color: var(--core-text-20-light-mode-color);
    --core-text-20-dark-mode-color: rgba(255,255,255,0.55);
    --core-text-20-light-mode-color: rgba(0,0,0,0.55);
    --core-text-30-color: var(--core-text-30-light-mode-color);
    --core-text-30-dark-mode-color: rgba(255,255,255,0.3);
    --core-text-30-light-mode-color: rgba(0,0,0,0.3);
    --core-text-black-color: var(--core-text-black-light-mode-color);
    --core-text-black-dark-mode-color: rgba(0,0,0,0.9);
    --core-text-black-light-mode-color: rgba(0,0,0,0.9);
    --core-text-inverse-color: var(--core-text-inverse-light-mode-color);
    --core-text-inverse-dark-mode-color: rgba(0,0,0,0.9);
    --core-text-inverse-light-mode-color: rgba(255,255,255,0.95);
    --core-text-size: var(--core-small-layout-size-text-size);
    --core-text-white-color: var(--core-text-white-light-mode-color);
    --core-text-white-dark-mode-color: rgba(255,255,255,1);
    --core-text-white-light-mode-color: rgba(255,255,255,1);
    --core-type-bold-weight: 700;
    --core-type-display-10-case: regular;
    --core-type-display-10-large-layout-size-leading: 40px;
    --core-type-display-10-large-layout-size-size: 32px;
    --core-type-display-10-large-layout-size-tracking: 0;
    --core-type-display-10-leading: var(--core-type-display-10-small-layout-size-leading);
    --core-type-display-10-medium-layout-size-leading: 40px;
    --core-type-display-10-medium-layout-size-size: 32px;
    --core-type-display-10-medium-layout-size-tracking: 0;
    --core-type-display-10-scale: heading-30;
    --core-type-display-10-size: var(--core-type-display-10-small-layout-size-size);
    --core-type-display-10-small-layout-size-leading: 40px;
    --core-type-display-10-small-layout-size-size: 32px;
    --core-type-display-10-small-layout-size-tracking: 0;
    --core-type-display-10-tracking: var(--core-type-display-10-small-layout-size-tracking);
    --core-type-display-10-weight: var(--core-type-bold-weight);
    --core-type-emphasis-10-case: regular;
    --core-type-emphasis-10-large-layout-size-leading: var(--core-type-paragraph-10-medium-layout-size-leading);
    --core-type-emphasis-10-large-layout-size-size: var(--core-type-paragraph-10-medium-layout-size-size);
    --core-type-emphasis-10-large-layout-size-tracking: var(--core-type-paragraph-10-medium-layout-size-tracking);
    --core-type-emphasis-10-leading: var(--core-type-emphasis-10-small-layout-size-leading);
    --core-type-emphasis-10-medium-layout-size-leading: var(--core-type-paragraph-10-small-layout-size-leading);
    --core-type-emphasis-10-medium-layout-size-size: var(--core-type-paragraph-10-small-layout-size-size);
    --core-type-emphasis-10-medium-layout-size-tracking: var(--core-type-paragraph-10-small-layout-size-tracking);
    --core-type-emphasis-10-scale: paragraph-10;
    --core-type-emphasis-10-size: var(--core-type-emphasis-10-small-layout-size-size);
    --core-type-emphasis-10-small-layout-size-leading: 18px;
    --core-type-emphasis-10-small-layout-size-size: 12px;
    --core-type-emphasis-10-small-layout-size-tracking: 0;
    --core-type-emphasis-10-tracking: var(--core-type-emphasis-10-small-layout-size-tracking);
    --core-type-emphasis-10-weight: var(--core-type-medium-weight);
    --core-type-emphasis-20-case: regular;
    --core-type-emphasis-20-large-layout-size-leading: var(--core-type-emphasis-20-medium-layout-size-leading);
    --core-type-emphasis-20-large-layout-size-size: var(--core-type-emphasis-20-medium-layout-size-size);
    --core-type-emphasis-20-large-layout-size-tracking: var(--core-type-emphasis-20-medium-layout-size-tracking);
    --core-type-emphasis-20-leading: var(--core-type-emphasis-20-small-layout-size-leading);
    --core-type-emphasis-20-medium-layout-size-leading: var(--core-type-emphasis-20-small-layout-size-leading);
    --core-type-emphasis-20-medium-layout-size-size: var(--core-type-emphasis-20-small-layout-size-size);
    --core-type-emphasis-20-medium-layout-size-tracking: var(--core-type-emphasis-20-small-layout-size-tracking);
    --core-type-emphasis-20-scale: paragraph-20;
    --core-type-emphasis-20-size: var(--core-type-emphasis-20-small-layout-size-size);
    --core-type-emphasis-20-small-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --core-type-emphasis-20-small-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --core-type-emphasis-20-small-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --core-type-emphasis-20-tracking: var(--core-type-emphasis-20-small-layout-size-tracking);
    --core-type-emphasis-20-weight: var(--core-type-medium-weight);
    --core-type-emphasis-30-case: regular;
    --core-type-emphasis-30-large-layout-size-leading: var(--core-type-emphasis-30-medium-layout-size-leading);
    --core-type-emphasis-30-large-layout-size-size: var(--core-type-emphasis-30-medium-layout-size-size);
    --core-type-emphasis-30-large-layout-size-tracking: var(--core-type-emphasis-30-medium-layout-size-tracking);
    --core-type-emphasis-30-leading: var(--core-type-emphasis-30-small-layout-size-leading);
    --core-type-emphasis-30-medium-layout-size-leading: var(--core-type-emphasis-30-small-layout-size-leading);
    --core-type-emphasis-30-medium-layout-size-size: var(--core-type-emphasis-30-small-layout-size-size);
    --core-type-emphasis-30-medium-layout-size-tracking: var(--core-type-emphasis-30-small-layout-size-tracking);
    --core-type-emphasis-30-scale: paragraph-30;
    --core-type-emphasis-30-size: var(--core-type-emphasis-30-small-layout-size-size);
    --core-type-emphasis-30-small-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --core-type-emphasis-30-small-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --core-type-emphasis-30-small-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --core-type-emphasis-30-tracking: var(--core-type-emphasis-30-small-layout-size-tracking);
    --core-type-emphasis-30-weight: var(--core-type-medium-weight);
    --core-type-heading-10-case: regular;
    --core-type-heading-10-large-layout-size-leading: 22px;
    --core-type-heading-10-large-layout-size-size: 14px;
    --core-type-heading-10-large-layout-size-tracking: 0.9;
    --core-type-heading-10-leading: var(--core-type-heading-10-small-layout-size-leading);
    --core-type-heading-10-medium-layout-size-leading: 22px;
    --core-type-heading-10-medium-layout-size-size: 14px;
    --core-type-heading-10-medium-layout-size-tracking: 0.9;
    --core-type-heading-10-scale: heading-10;
    --core-type-heading-10-size: var(--core-type-heading-10-small-layout-size-size);
    --core-type-heading-10-small-layout-size-leading: 22px;
    --core-type-heading-10-small-layout-size-size: 14px;
    --core-type-heading-10-small-layout-size-tracking: 0.9;
    --core-type-heading-10-tracking: var(--core-type-heading-10-small-layout-size-tracking);
    --core-type-heading-10-weight: var(--core-type-bold-weight);
    --core-type-heading-20-case: regular;
    --core-type-heading-20-large-layout-size-leading: 26px;
    --core-type-heading-20-large-layout-size-size: 18px;
    --core-type-heading-20-large-layout-size-tracking: 0.6;
    --core-type-heading-20-leading: var(--core-type-heading-20-small-layout-size-leading);
    --core-type-heading-20-medium-layout-size-leading: 26px;
    --core-type-heading-20-medium-layout-size-size: 18px;
    --core-type-heading-20-medium-layout-size-tracking: 0.6;
    --core-type-heading-20-scale: heading-20;
    --core-type-heading-20-size: var(--core-type-heading-20-small-layout-size-size);
    --core-type-heading-20-small-layout-size-leading: 26px;
    --core-type-heading-20-small-layout-size-size: 18px;
    --core-type-heading-20-small-layout-size-tracking: 0.6;
    --core-type-heading-20-tracking: var(--core-type-heading-20-small-layout-size-tracking);
    --core-type-heading-20-weight: var(--core-type-bold-weight);
    --core-type-heading-30-case: regular;
    --core-type-heading-30-large-layout-size-leading: 32px;
    --core-type-heading-30-large-layout-size-size: 24px;
    --core-type-heading-30-large-layout-size-tracking: 0.3;
    --core-type-heading-30-leading: var(--core-type-heading-30-small-layout-size-leading);
    --core-type-heading-30-medium-layout-size-leading: 32px;
    --core-type-heading-30-medium-layout-size-size: 24px;
    --core-type-heading-30-medium-layout-size-tracking: 0.3;
    --core-type-heading-30-scale: heading-30;
    --core-type-heading-30-size: var(--core-type-heading-30-small-layout-size-size);
    --core-type-heading-30-small-layout-size-leading: 32px;
    --core-type-heading-30-small-layout-size-size: 24px;
    --core-type-heading-30-small-layout-size-tracking: 0.3;
    --core-type-heading-30-tracking: var(--core-type-heading-30-small-layout-size-tracking);
    --core-type-heading-30-weight: var(--core-type-bold-weight);
    --core-type-heading-5-case: uppercase;
    --core-type-heading-5-large-layout-size-leading: 20px;
    --core-type-heading-5-large-layout-size-size: 12px;
    --core-type-heading-5-large-layout-size-tracking: 5;
    --core-type-heading-5-leading: var(--core-type-heading-5-small-layout-size-leading);
    --core-type-heading-5-medium-layout-size-leading: 20px;
    --core-type-heading-5-medium-layout-size-size: 12px;
    --core-type-heading-5-medium-layout-size-tracking: 5;
    --core-type-heading-5-scale: heading-5;
    --core-type-heading-5-size: var(--core-type-heading-5-small-layout-size-size);
    --core-type-heading-5-small-layout-size-leading: 20px;
    --core-type-heading-5-small-layout-size-size: 12px;
    --core-type-heading-5-small-layout-size-tracking: 5;
    --core-type-heading-5-tracking: var(--core-type-heading-5-small-layout-size-tracking);
    --core-type-heading-5-weight: var(--core-type-medium-weight);
    --core-type-medium-weight: 500;
    --core-type-paragraph-10-case: regular;
    --core-type-paragraph-10-large-layout-size-leading: var(--core-type-paragraph-10-medium-layout-size-leading);
    --core-type-paragraph-10-large-layout-size-size: var(--core-type-paragraph-10-medium-layout-size-size);
    --core-type-paragraph-10-large-layout-size-tracking: var(--core-type-paragraph-10-medium-layout-size-tracking);
    --core-type-paragraph-10-leading: var(--core-type-paragraph-10-small-layout-size-leading);
    --core-type-paragraph-10-medium-layout-size-leading: var(--core-type-paragraph-10-small-layout-size-leading);
    --core-type-paragraph-10-medium-layout-size-size: var(--core-type-paragraph-10-small-layout-size-size);
    --core-type-paragraph-10-medium-layout-size-tracking: var(--core-type-paragraph-10-small-layout-size-tracking);
    --core-type-paragraph-10-scale: paragraph-10;
    --core-type-paragraph-10-size: var(--core-type-paragraph-10-small-layout-size-size);
    --core-type-paragraph-10-small-layout-size-leading: 18px;
    --core-type-paragraph-10-small-layout-size-size: 12px;
    --core-type-paragraph-10-small-layout-size-tracking: 0;
    --core-type-paragraph-10-tracking: var(--core-type-paragraph-10-small-layout-size-tracking);
    --core-type-paragraph-10-weight: var(--core-type-regular-weight);
    --core-type-paragraph-20-case: regular;
    --core-type-paragraph-20-large-layout-size-leading: var(--core-type-paragraph-20-medium-layout-size-leading);
    --core-type-paragraph-20-large-layout-size-size: var(--core-type-paragraph-20-medium-layout-size-size);
    --core-type-paragraph-20-large-layout-size-tracking: var(--core-type-paragraph-20-medium-layout-size-tracking);
    --core-type-paragraph-20-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --core-type-paragraph-20-medium-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --core-type-paragraph-20-medium-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --core-type-paragraph-20-medium-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --core-type-paragraph-20-scale: paragraph-20;
    --core-type-paragraph-20-size: var(--core-type-paragraph-20-small-layout-size-size);
    --core-type-paragraph-20-small-layout-size-leading: 22px;
    --core-type-paragraph-20-small-layout-size-size: 14px;
    --core-type-paragraph-20-small-layout-size-tracking: 0;
    --core-type-paragraph-20-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --core-type-paragraph-20-weight: var(--core-type-regular-weight);
    --core-type-paragraph-30-case: regular;
    --core-type-paragraph-30-large-layout-size-leading: var(--core-type-paragraph-30-medium-layout-size-leading);
    --core-type-paragraph-30-large-layout-size-size: var(--core-type-paragraph-30-medium-layout-size-size);
    --core-type-paragraph-30-large-layout-size-tracking: var(--core-type-paragraph-30-medium-layout-size-tracking);
    --core-type-paragraph-30-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --core-type-paragraph-30-medium-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --core-type-paragraph-30-medium-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --core-type-paragraph-30-medium-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --core-type-paragraph-30-scale: paragraph-30;
    --core-type-paragraph-30-size: var(--core-type-paragraph-30-small-layout-size-size);
    --core-type-paragraph-30-small-layout-size-leading: 24px;
    --core-type-paragraph-30-small-layout-size-size: 16px;
    --core-type-paragraph-30-small-layout-size-tracking: 0;
    --core-type-paragraph-30-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --core-type-paragraph-30-weight: var(--core-type-regular-weight);
    --core-type-regular-weight: 400;
    --core-warning-10-color: var(--core-warning-10-light-mode-color);
    --core-warning-10-dark-mode-color: var(--core-gold-10-dark-mode-color);
    --core-warning-10-light-mode-color: var(--core-gold-10-light-mode-color);
    --core-warning-20-color: var(--core-warning-20-light-mode-color);
    --core-warning-20-dark-mode-color: var(--core-gold-20-dark-mode-color);
    --core-warning-20-light-mode-color: var(--core-gold-20-light-mode-color);
    --core-warning-30-color: var(--core-warning-30-light-mode-color);
    --core-warning-30-dark-mode-color: var(--core-gold-30-dark-mode-color);
    --core-warning-30-light-mode-color: var(--core-gold-30-light-mode-color);
    --core-warning-40-color: var(--core-warning-40-light-mode-color);
    --core-warning-40-dark-mode-color: var(--core-gold-40-dark-mode-color);
    --core-warning-40-light-mode-color: var(--core-gold-40-light-mode-color);
    --core-warning-fill-color: var(--core-warning-fill-light-mode-color);
    --core-warning-fill-dark-mode-color: var(--core-gold-fill-dark-mode-color);
    --core-warning-fill-light-mode-color: var(--core-gold-fill-light-mode-color);
    --core-warning-text-color: var(--core-warning-text-light-mode-color);
    --core-warning-text-dark-mode-color: var(--core-gold-text-dark-mode-color);
    --core-warning-text-light-mode-color: var(--core-gold-text-light-mode-color);
    --core-wide-viewport-grid-column-count: var(--core-extra-wide-viewport-grid-column-count);
    --core-wide-viewport-grid-horizontal-spacing: var(--core-extra-wide-viewport-grid-horizontal-spacing);
    --core-wide-viewport-grid-item-full-size-column-span: var(--core-extra-wide-viewport-grid-item-full-size-column-span);
    --core-wide-viewport-grid-item-large-size-column-span: var(--core-extra-wide-viewport-grid-item-large-size-column-span);
    --core-wide-viewport-grid-item-medium-size-column-span: var(--core-extra-wide-viewport-grid-item-medium-size-column-span);
    --core-wide-viewport-grid-item-small-size-column-span: var(--core-extra-wide-viewport-grid-item-small-size-column-span);
    --core-wide-viewport-grid-vertical-spacing: var(--core-extra-wide-viewport-grid-vertical-spacing);
    --core-yellow-10-color: var(--core-yellow-10-light-mode-color);
    --core-yellow-10-dark-mode-color: #CC9900;
    --core-yellow-10-light-mode-color: #CC9900;
    --core-yellow-20-color: var(--core-yellow-20-light-mode-color);
    --core-yellow-20-dark-mode-color: #E5AC00;
    --core-yellow-20-light-mode-color: #E5AC00;
    --core-yellow-30-color: var(--core-yellow-30-light-mode-color);
    --core-yellow-30-dark-mode-color: #594300;
    --core-yellow-30-light-mode-color: #FFF2CC;
    --core-yellow-40-color: var(--core-yellow-40-light-mode-color);
    --core-yellow-40-dark-mode-color: #332600;
    --core-yellow-40-light-mode-color: #FFF9E5;
    --core-yellow-fill-color: var(--core-yellow-fill-light-mode-color);
    --core-yellow-fill-dark-mode-color: #FFBF00;
    --core-yellow-fill-light-mode-color: #FFBF00;
    --core-yellow-text-color: var(--core-yellow-text-light-mode-color);
    --core-yellow-text-dark-mode-color: #FFBF00;
    --core-yellow-text-light-mode-color: #876500;
    --accessory-icon-variant-border-radius: 3px;
    --accessory-icon-variant-size: 24px;
    --accessory-image-variant-border-radius: 5px;
    --accessory-image-variant-size: 40px;
    --action-card-background-color: var(--action-card-background-light-mode-color);
    --action-card-background-dark-mode-color: rgba(20,20,20,1);
    --action-card-background-light-mode-color: rgba(255,255,255,1);
    --action-card-border-disabled-state-color: var(--action-card-border-disabled-state-light-mode-color);
    --action-card-border-disabled-state-dark-mode-color: rgba(255,255,255,0.05);
    --action-card-border-disabled-state-light-mode-color: rgba(0,0,0,0.03);
    --action-card-border-disabled-state-width: 1px;
    --action-card-border-hover-state-color: var(--action-card-border-hover-state-light-mode-color);
    --action-card-border-hover-state-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --action-card-border-hover-state-light-mode-color: var(--core-blue-fill-light-mode-color);
    --action-card-border-hover-state-width: 1px;
    --action-card-border-radius: 6px;
    --action-card-border-selected-state-color: var(--action-card-border-selected-state-light-mode-color);
    --action-card-border-selected-state-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --action-card-border-selected-state-light-mode-color: var(--core-blue-fill-light-mode-color);
    --action-card-border-selected-state-width: 2px;
    --action-card-disabled-state-content-opacity: 0.4;
    --action-card-focus-ring-border-size: var(--core-focus-ring-border-size);
    --action-card-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --action-card-focus-ring-color: var(--action-card-focus-ring-light-mode-color);
    --action-card-focus-ring-dark-mode-color: var(--core-focus-dark-mode-color);
    --action-card-focus-ring-light-mode-color: var(--core-focus-light-mode-color);
    --action-card-normal-state-content-opacity: 1;
    --action-card-padding-horizontal-size: 16px;
    --action-card-padding-vertical-size: 16px;
    --action-card-spacing: 16px;
    --button-border-radius: 6px;
    --button-compact-size-content-spacing: var(--button-normal-size-content-spacing);
    --button-compact-size-minimum-height: 40px;
    --button-compact-size-minimum-width: 60px;
    --button-compact-size-primary-rank-horizontal-padding-size: 12px;
    --button-compact-size-secondary-rank-horizontal-padding-size: var(--button-compact-size-primary-rank-horizontal-padding-size);
    --button-compact-size-tertiary-rank-horizontal-padding-size: 8px;
    --button-compact-size-text-case: regular;
    --button-compact-size-text-large-layout-size-leading: var(--core-type-emphasis-20-medium-layout-size-leading);
    --button-compact-size-text-large-layout-size-size: var(--core-type-emphasis-20-medium-layout-size-size);
    --button-compact-size-text-large-layout-size-tracking: var(--core-type-emphasis-20-medium-layout-size-tracking);
    --button-compact-size-text-leading: var(--button-compact-size-text-small-layout-size-leading);
    --button-compact-size-text-medium-layout-size-leading: var(--core-type-emphasis-20-small-layout-size-leading);
    --button-compact-size-text-medium-layout-size-size: var(--core-type-emphasis-20-small-layout-size-size);
    --button-compact-size-text-medium-layout-size-tracking: var(--core-type-emphasis-20-small-layout-size-tracking);
    --button-compact-size-text-scale: paragraph-20;
    --button-compact-size-text-size: var(--button-compact-size-text-small-layout-size-size);
    --button-compact-size-text-small-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --button-compact-size-text-small-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --button-compact-size-text-small-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --button-compact-size-text-tracking: var(--button-compact-size-text-small-layout-size-tracking);
    --button-compact-size-text-weight: var(--core-type-medium-weight);
    --button-compact-size-vertical-padding-size: var(--button-small-layout-size-compact-size-vertical-padding-size);
    --button-dark-mode-focus-ring-color: var(--core-focus-ring-dark-mode-color);
    --button-destructive-variant-primary-rank-disabled-state-background-color: var(--button-destructive-variant-primary-rank-disabled-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-disabled-state-dark-mode-background-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-background-color);
    --button-destructive-variant-primary-rank-disabled-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-label-color);
    --button-destructive-variant-primary-rank-disabled-state-label-color: var(--button-destructive-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-disabled-state-light-mode-background-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-disabled-state-light-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-focus-state-background-color: var(--button-destructive-variant-primary-rank-focus-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-focus-state-dark-mode-background-color: var(--button-destructive-variant-primary-rank-hover-state-dark-mode-background-color);
    --button-destructive-variant-primary-rank-focus-state-dark-mode-label-color: var(--button-destructive-variant-primary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-primary-rank-focus-state-label-color: var(--button-destructive-variant-primary-rank-focus-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-focus-state-light-mode-background-color: var(--button-destructive-variant-primary-rank-hover-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-focus-state-light-mode-label-color: var(--button-destructive-variant-primary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-hover-state-background-color: var(--button-destructive-variant-primary-rank-hover-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-hover-state-dark-mode-background-color: var(--core-critical-20-dark-mode-color);
    --button-destructive-variant-primary-rank-hover-state-dark-mode-label-color: var(--button-destructive-variant-primary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-primary-rank-hover-state-label-color: var(--button-destructive-variant-primary-rank-hover-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-hover-state-light-mode-background-color: var(--core-critical-20-light-mode-color);
    --button-destructive-variant-primary-rank-hover-state-light-mode-label-color: var(--button-destructive-variant-primary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-normal-state-background-color: var(--button-destructive-variant-primary-rank-normal-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-normal-state-dark-mode-background-color: var(--core-critical-fill-dark-mode-color);
    --button-destructive-variant-primary-rank-normal-state-dark-mode-label-color: var(--core-text-white-dark-mode-color);
    --button-destructive-variant-primary-rank-normal-state-label-color: var(--button-destructive-variant-primary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-normal-state-light-mode-background-color: var(--core-critical-fill-light-mode-color);
    --button-destructive-variant-primary-rank-normal-state-light-mode-label-color: var(--core-text-white-light-mode-color);
    --button-destructive-variant-primary-rank-pressed-state-background-color: var(--button-destructive-variant-primary-rank-pressed-state-light-mode-background-color);
    --button-destructive-variant-primary-rank-pressed-state-dark-mode-background-color: var(--core-critical-10-dark-mode-color);
    --button-destructive-variant-primary-rank-pressed-state-dark-mode-label-color: var(--button-destructive-variant-primary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-primary-rank-pressed-state-label-color: var(--button-destructive-variant-primary-rank-pressed-state-light-mode-label-color);
    --button-destructive-variant-primary-rank-pressed-state-light-mode-background-color: var(--core-critical-10-light-mode-color);
    --button-destructive-variant-primary-rank-pressed-state-light-mode-label-color: var(--button-destructive-variant-primary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-disabled-state-background-color: var(--button-destructive-variant-secondary-rank-disabled-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-disabled-state-dark-mode-background-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-background-color);
    --button-destructive-variant-secondary-rank-disabled-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-label-color);
    --button-destructive-variant-secondary-rank-disabled-state-label-color: var(--button-destructive-variant-secondary-rank-disabled-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-disabled-state-light-mode-background-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-disabled-state-light-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-focus-state-background-color: var(--button-destructive-variant-secondary-rank-focus-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-focus-state-dark-mode-background-color: var(--button-destructive-variant-secondary-rank-hover-state-dark-mode-background-color);
    --button-destructive-variant-secondary-rank-focus-state-dark-mode-label-color: var(--button-destructive-variant-secondary-rank-hover-state-dark-mode-label-color);
    --button-destructive-variant-secondary-rank-focus-state-label-color: var(--button-destructive-variant-secondary-rank-focus-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-focus-state-light-mode-background-color: var(--button-destructive-variant-secondary-rank-hover-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-focus-state-light-mode-label-color: var(--button-destructive-variant-secondary-rank-hover-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-hover-state-background-color: var(--button-destructive-variant-secondary-rank-hover-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-hover-state-dark-mode-background-color: var(--core-critical-40-dark-mode-color);
    --button-destructive-variant-secondary-rank-hover-state-dark-mode-label-color: var(--button-destructive-variant-secondary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-secondary-rank-hover-state-label-color: var(--button-destructive-variant-secondary-rank-hover-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-hover-state-light-mode-background-color: var(--core-critical-40-light-mode-color);
    --button-destructive-variant-secondary-rank-hover-state-light-mode-label-color: var(--button-destructive-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-normal-state-background-color: var(--button-destructive-variant-secondary-rank-normal-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-normal-state-dark-mode-background-color: var(--button-normal-variant-secondary-rank-normal-state-dark-mode-background-color);
    --button-destructive-variant-secondary-rank-normal-state-dark-mode-label-color: var(--core-critical-text-dark-mode-color);
    --button-destructive-variant-secondary-rank-normal-state-label-color: var(--button-destructive-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-normal-state-light-mode-background-color: var(--button-normal-variant-secondary-rank-normal-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-normal-state-light-mode-label-color: var(--core-critical-text-light-mode-color);
    --button-destructive-variant-secondary-rank-pressed-state-background-color: var(--button-destructive-variant-secondary-rank-pressed-state-light-mode-background-color);
    --button-destructive-variant-secondary-rank-pressed-state-dark-mode-background-color: var(--core-critical-30-dark-mode-color);
    --button-destructive-variant-secondary-rank-pressed-state-dark-mode-label-color: var(--button-destructive-variant-secondary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-secondary-rank-pressed-state-label-color: var(--button-destructive-variant-secondary-rank-pressed-state-light-mode-label-color);
    --button-destructive-variant-secondary-rank-pressed-state-light-mode-background-color: var(--core-critical-30-light-mode-color);
    --button-destructive-variant-secondary-rank-pressed-state-light-mode-label-color: var(--button-destructive-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-disabled-state-background-color: var(--button-destructive-variant-tertiary-rank-disabled-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-disabled-state-dark-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-background-color);
    --button-destructive-variant-tertiary-rank-disabled-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-label-color);
    --button-destructive-variant-tertiary-rank-disabled-state-label-color: var(--button-destructive-variant-tertiary-rank-disabled-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-disabled-state-light-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-disabled-state-light-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-focus-state-background-color: var(--button-destructive-variant-tertiary-rank-focus-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-focus-state-dark-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-background-color);
    --button-destructive-variant-tertiary-rank-focus-state-dark-mode-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-tertiary-rank-focus-state-label-color: var(--button-destructive-variant-tertiary-rank-focus-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-focus-state-light-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-focus-state-light-mode-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-hover-state-background-color: var(--button-destructive-variant-tertiary-rank-hover-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-hover-state-dark-mode-background-color: var(--button-destructive-variant-secondary-rank-hover-state-dark-mode-background-color);
    --button-destructive-variant-tertiary-rank-hover-state-dark-mode-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-tertiary-rank-hover-state-label-color: var(--button-destructive-variant-tertiary-rank-hover-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-hover-state-light-mode-background-color: var(--button-destructive-variant-secondary-rank-hover-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-hover-state-light-mode-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-normal-state-background-color: var(--button-destructive-variant-tertiary-rank-normal-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-normal-state-dark-mode-background-color: transparent;
    --button-destructive-variant-tertiary-rank-normal-state-dark-mode-label-color: var(--core-critical-text-dark-mode-color);
    --button-destructive-variant-tertiary-rank-normal-state-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-normal-state-light-mode-background-color: transparent;
    --button-destructive-variant-tertiary-rank-normal-state-light-mode-label-color: var(--core-critical-text-light-mode-color);
    --button-destructive-variant-tertiary-rank-pressed-state-background-color: var(--button-destructive-variant-tertiary-rank-pressed-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-pressed-state-dark-mode-background-color: var(--button-destructive-variant-secondary-rank-pressed-state-dark-mode-background-color);
    --button-destructive-variant-tertiary-rank-pressed-state-dark-mode-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-dark-mode-label-color);
    --button-destructive-variant-tertiary-rank-pressed-state-label-color: var(--button-destructive-variant-tertiary-rank-pressed-state-light-mode-label-color);
    --button-destructive-variant-tertiary-rank-pressed-state-light-mode-background-color: var(--button-destructive-variant-secondary-rank-pressed-state-light-mode-background-color);
    --button-destructive-variant-tertiary-rank-pressed-state-light-mode-label-color: var(--button-destructive-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-focus-ring-border-size: var(--core-focus-ring-border-size);
    --button-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --button-focus-ring-color: var(--button-light-mode-focus-ring-color);
    --button-large-layout-size-compact-size-vertical-padding-size: var(--button-small-layout-size-compact-size-vertical-padding-size);
    --button-large-layout-size-normal-size-vertical-padding-size: var(--button-small-layout-size-normal-size-vertical-padding-size);
    --button-large-layout-size-vertical-padding-size: var(--button-small-layout-size-normal-size-vertical-padding-size);
    --button-light-mode-focus-ring-color: var(--core-focus-ring-light-mode-color);
    --button-medium-layout-size-compact-size-vertical-padding-size: 12px;
    --button-medium-layout-size-normal-size-vertical-padding-size: 20px;
    --button-medium-layout-size-vertical-padding-size: 20px;
    --button-minimum-width: 72px;
    --button-normal-size-content-spacing: var(--core-metrics-spacing-100);
    --button-normal-size-minimum-height: 48px;
    --button-normal-size-minimum-width: 72px;
    --button-normal-size-primary-rank-horizontal-padding-size: 16px;
    --button-normal-size-secondary-rank-horizontal-padding-size: var(--button-normal-size-primary-rank-horizontal-padding-size);
    --button-normal-size-tertiary-rank-horizontal-padding-size: 8px;
    --button-normal-size-text-case: regular;
    --button-normal-size-text-large-layout-size-leading: var(--core-type-emphasis-30-medium-layout-size-leading);
    --button-normal-size-text-large-layout-size-size: var(--core-type-emphasis-30-medium-layout-size-size);
    --button-normal-size-text-large-layout-size-tracking: var(--core-type-emphasis-30-medium-layout-size-tracking);
    --button-normal-size-text-leading: var(--button-normal-size-text-small-layout-size-leading);
    --button-normal-size-text-medium-layout-size-leading: var(--core-type-emphasis-30-small-layout-size-leading);
    --button-normal-size-text-medium-layout-size-size: var(--core-type-emphasis-30-small-layout-size-size);
    --button-normal-size-text-medium-layout-size-tracking: var(--core-type-emphasis-30-small-layout-size-tracking);
    --button-normal-size-text-scale: paragraph-30;
    --button-normal-size-text-size: var(--button-normal-size-text-small-layout-size-size);
    --button-normal-size-text-small-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --button-normal-size-text-small-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --button-normal-size-text-small-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --button-normal-size-text-tracking: var(--button-normal-size-text-small-layout-size-tracking);
    --button-normal-size-text-weight: var(--core-type-medium-weight);
    --button-normal-size-vertical-padding-size: var(--button-small-layout-size-normal-size-vertical-padding-size);
    --button-normal-variant-primary-rank-disabled-state-background-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-background-color);
    --button-normal-variant-primary-rank-disabled-state-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --button-normal-variant-primary-rank-disabled-state-dark-mode-label-color: var(--core-text-30-dark-mode-color);
    --button-normal-variant-primary-rank-disabled-state-label-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-normal-variant-primary-rank-disabled-state-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --button-normal-variant-primary-rank-disabled-state-light-mode-label-color: var(--core-text-30-light-mode-color);
    --button-normal-variant-primary-rank-focus-state-background-color: var(--button-normal-variant-primary-rank-focus-state-light-mode-background-color);
    --button-normal-variant-primary-rank-focus-state-dark-mode-background-color: var(--core-emphasis-fill-dark-mode-color);
    --button-normal-variant-primary-rank-focus-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-primary-rank-focus-state-label-color: var(--button-normal-variant-primary-rank-focus-state-light-mode-label-color);
    --button-normal-variant-primary-rank-focus-state-light-mode-background-color: var(--core-emphasis-fill-light-mode-color);
    --button-normal-variant-primary-rank-focus-state-light-mode-label-color: var(--button-normal-variant-primary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-primary-rank-hover-state-background-color: var(--button-normal-variant-primary-rank-hover-state-light-mode-background-color);
    --button-normal-variant-primary-rank-hover-state-dark-mode-background-color: var(--core-emphasis-20-dark-mode-color);
    --button-normal-variant-primary-rank-hover-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-primary-rank-hover-state-label-color: var(--button-normal-variant-primary-rank-hover-state-light-mode-label-color);
    --button-normal-variant-primary-rank-hover-state-light-mode-background-color: var(--core-emphasis-20-light-mode-color);
    --button-normal-variant-primary-rank-hover-state-light-mode-label-color: var(--button-normal-variant-primary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-primary-rank-normal-state-background-color: var(--button-normal-variant-primary-rank-normal-state-light-mode-background-color);
    --button-normal-variant-primary-rank-normal-state-dark-mode-background-color: var(--core-emphasis-fill-dark-mode-color);
    --button-normal-variant-primary-rank-normal-state-dark-mode-label-color: var(--core-text-white-dark-mode-color);
    --button-normal-variant-primary-rank-normal-state-label-color: var(--button-normal-variant-primary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-primary-rank-normal-state-light-mode-background-color: var(--core-emphasis-fill-light-mode-color);
    --button-normal-variant-primary-rank-normal-state-light-mode-label-color: var(--core-text-white-light-mode-color);
    --button-normal-variant-primary-rank-pressed-state-background-color: var(--button-normal-variant-primary-rank-pressed-state-light-mode-background-color);
    --button-normal-variant-primary-rank-pressed-state-dark-mode-background-color: var(--core-emphasis-10-dark-mode-color);
    --button-normal-variant-primary-rank-pressed-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-primary-rank-pressed-state-label-color: var(--button-normal-variant-primary-rank-pressed-state-light-mode-label-color);
    --button-normal-variant-primary-rank-pressed-state-light-mode-background-color: var(--core-emphasis-10-light-mode-color);
    --button-normal-variant-primary-rank-pressed-state-light-mode-label-color: var(--button-normal-variant-primary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-disabled-state-background-color: var(--button-normal-variant-secondary-rank-disabled-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-disabled-state-dark-mode-background-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-background-color);
    --button-normal-variant-secondary-rank-disabled-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-label-color);
    --button-normal-variant-secondary-rank-disabled-state-label-color: var(--button-normal-variant-secondary-rank-disabled-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-disabled-state-light-mode-background-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-disabled-state-light-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-focus-state-background-color: var(--button-normal-variant-secondary-rank-focus-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-focus-state-dark-mode-background-color: var(--button-normal-variant-secondary-rank-hover-state-dark-mode-background-color);
    --button-normal-variant-secondary-rank-focus-state-dark-mode-label-color: var(--button-normal-variant-secondary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-secondary-rank-focus-state-label-color: var(--button-normal-variant-secondary-rank-focus-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-focus-state-light-mode-background-color: var(--button-normal-variant-secondary-rank-hover-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-focus-state-light-mode-label-color: var(--button-normal-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-hover-state-background-color: var(--button-normal-variant-secondary-rank-hover-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-hover-state-dark-mode-background-color: var(--core-emphasis-40-dark-mode-color);
    --button-normal-variant-secondary-rank-hover-state-dark-mode-label-color: var(--button-normal-variant-secondary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-secondary-rank-hover-state-label-color: var(--button-normal-variant-secondary-rank-hover-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-hover-state-light-mode-background-color: var(--core-emphasis-40-light-mode-color);
    --button-normal-variant-secondary-rank-hover-state-light-mode-label-color: var(--button-normal-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-normal-state-background-color: var(--button-normal-variant-secondary-rank-normal-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-normal-state-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --button-normal-variant-secondary-rank-normal-state-dark-mode-label-color: var(--core-emphasis-text-dark-mode-color);
    --button-normal-variant-secondary-rank-normal-state-label-color: var(--button-normal-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-normal-state-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --button-normal-variant-secondary-rank-normal-state-light-mode-label-color: var(--core-emphasis-text-light-mode-color);
    --button-normal-variant-secondary-rank-pressed-state-background-color: var(--button-normal-variant-secondary-rank-pressed-state-light-mode-background-color);
    --button-normal-variant-secondary-rank-pressed-state-dark-mode-background-color: var(--core-emphasis-30-dark-mode-color);
    --button-normal-variant-secondary-rank-pressed-state-dark-mode-label-color: var(--button-normal-variant-secondary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-secondary-rank-pressed-state-label-color: var(--button-normal-variant-secondary-rank-pressed-state-light-mode-label-color);
    --button-normal-variant-secondary-rank-pressed-state-light-mode-background-color: var(--core-emphasis-30-light-mode-color);
    --button-normal-variant-secondary-rank-pressed-state-light-mode-label-color: var(--button-normal-variant-secondary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-disabled-state-background-color: var(--button-normal-variant-tertiary-rank-disabled-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-disabled-state-dark-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-background-color);
    --button-normal-variant-tertiary-rank-disabled-state-dark-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-dark-mode-label-color);
    --button-normal-variant-tertiary-rank-disabled-state-label-color: var(--button-normal-variant-tertiary-rank-disabled-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-disabled-state-light-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-disabled-state-light-mode-label-color: var(--button-normal-variant-primary-rank-disabled-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-focus-state-background-color: var(--button-normal-variant-tertiary-rank-focus-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-focus-state-dark-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-background-color);
    --button-normal-variant-tertiary-rank-focus-state-dark-mode-label-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-tertiary-rank-focus-state-label-color: var(--button-normal-variant-tertiary-rank-focus-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-focus-state-light-mode-background-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-focus-state-light-mode-label-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-hover-state-background-color: var(--button-normal-variant-tertiary-rank-hover-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-hover-state-dark-mode-background-color: var(--button-normal-variant-secondary-rank-hover-state-dark-mode-background-color);
    --button-normal-variant-tertiary-rank-hover-state-dark-mode-label-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-tertiary-rank-hover-state-label-color: var(--button-normal-variant-tertiary-rank-hover-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-hover-state-light-mode-background-color: var(--button-normal-variant-secondary-rank-hover-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-hover-state-light-mode-label-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-normal-state-background-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-normal-state-dark-mode-background-color: transparent;
    --button-normal-variant-tertiary-rank-normal-state-dark-mode-label-color: var(--core-emphasis-text-dark-mode-color);
    --button-normal-variant-tertiary-rank-normal-state-label-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-normal-state-light-mode-background-color: transparent;
    --button-normal-variant-tertiary-rank-normal-state-light-mode-label-color: var(--core-emphasis-text-light-mode-color);
    --button-normal-variant-tertiary-rank-pressed-state-background-color: var(--button-normal-variant-tertiary-rank-pressed-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-pressed-state-dark-mode-background-color: var(--button-normal-variant-secondary-rank-pressed-state-dark-mode-background-color);
    --button-normal-variant-tertiary-rank-pressed-state-dark-mode-label-color: var(--button-normal-variant-tertiary-rank-normal-state-dark-mode-label-color);
    --button-normal-variant-tertiary-rank-pressed-state-label-color: var(--button-normal-variant-tertiary-rank-pressed-state-light-mode-label-color);
    --button-normal-variant-tertiary-rank-pressed-state-light-mode-background-color: var(--button-normal-variant-secondary-rank-pressed-state-light-mode-background-color);
    --button-normal-variant-tertiary-rank-pressed-state-light-mode-label-color: var(--button-normal-variant-tertiary-rank-normal-state-light-mode-label-color);
    --button-primary-rank-horizontal-padding-size: 16px;
    --button-secondary-rank-horizontal-padding-size: var(--button-normal-size-primary-rank-horizontal-padding-size);
    --button-small-layout-size-compact-size-vertical-padding-size: 9px;
    --button-small-layout-size-normal-size-vertical-padding-size: 12px;
    --button-small-layout-size-vertical-padding-size: 12px;
    --button-tertiary-rank-horizontal-padding-size: 8px;
    --button-text-case: regular;
    --button-text-large-layout-size-leading: var(--core-type-emphasis-30-medium-layout-size-leading);
    --button-text-large-layout-size-size: var(--core-type-emphasis-30-medium-layout-size-size);
    --button-text-large-layout-size-tracking: var(--core-type-emphasis-30-medium-layout-size-tracking);
    --button-text-leading: var(--button-text-small-layout-size-leading);
    --button-text-medium-layout-size-leading: var(--core-type-emphasis-30-small-layout-size-leading);
    --button-text-medium-layout-size-size: var(--core-type-emphasis-30-small-layout-size-size);
    --button-text-medium-layout-size-tracking: var(--core-type-emphasis-30-small-layout-size-tracking);
    --button-text-scale: paragraph-30;
    --button-text-size: var(--button-text-small-layout-size-size);
    --button-text-small-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --button-text-small-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --button-text-small-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --button-text-tracking: var(--button-text-small-layout-size-tracking);
    --button-text-weight: var(--core-type-medium-weight);
    --button-vertical-padding-size: var(--button-small-layout-size-vertical-padding-size);
    --button-group-spacing: 16px;
    --checkbox-border-radius: 4px;
    --checkbox-border-size: 2px;
    --checkbox-dark-mode-focus-ring-color: var(--core-focus-ring-dark-mode-color);
    --checkbox-disabled-state-normal-validity-checked-value-background-color: var(--checkbox-disabled-state-normal-validity-checked-value-light-mode-background-color);
    --checkbox-disabled-state-normal-validity-checked-value-border-color: var(--checkbox-disabled-state-normal-validity-checked-value-light-mode-border-color);
    --checkbox-disabled-state-normal-validity-checked-value-dark-mode-background-color: var(--core-fill-30-dark-mode-color);
    --checkbox-disabled-state-normal-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-disabled-state-normal-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-disabled-state-normal-validity-checked-value-icon-color: var(--checkbox-disabled-state-normal-validity-checked-value-light-mode-icon-color);
    --checkbox-disabled-state-normal-validity-checked-value-light-mode-background-color: var(--core-fill-30-light-mode-color);
    --checkbox-disabled-state-normal-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-disabled-state-normal-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-background-color: var(--checkbox-disabled-state-normal-validity-indeterminate-value-light-mode-background-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-border-color: var(--checkbox-disabled-state-normal-validity-indeterminate-value-light-mode-border-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-disabled-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-disabled-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-dark-mode-icon-color: var(--core-fill-30-dark-mode-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-icon-color: var(--checkbox-disabled-state-normal-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-light-mode-background-color: var(--checkbox-disabled-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-light-mode-border-color: var(--checkbox-disabled-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-disabled-state-normal-validity-indeterminate-value-light-mode-icon-color: var(--core-fill-30-light-mode-color);
    --checkbox-disabled-state-normal-validity-unchecked-value-background-color: var(--checkbox-disabled-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-disabled-state-normal-validity-unchecked-value-border-color: var(--checkbox-disabled-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-disabled-state-normal-validity-unchecked-value-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --checkbox-disabled-state-normal-validity-unchecked-value-dark-mode-border-color: var(--core-fill-40-dark-mode-color);
    --checkbox-disabled-state-normal-validity-unchecked-value-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --checkbox-disabled-state-normal-validity-unchecked-value-light-mode-border-color: var(--core-fill-40-light-mode-color);
    --checkbox-focus-ring-border-size: var(--core-focus-ring-border-size);
    --checkbox-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --checkbox-focus-ring-color: var(--checkbox-light-mode-focus-ring-color);
    --checkbox-focus-state-invalid-validity-checked-value-background-color: var(--checkbox-focus-state-invalid-validity-checked-value-light-mode-background-color);
    --checkbox-focus-state-invalid-validity-checked-value-border-color: var(--checkbox-focus-state-invalid-validity-checked-value-light-mode-border-color);
    --checkbox-focus-state-invalid-validity-checked-value-dark-mode-background-color: var(--checkbox-hover-state-invalid-validity-checked-value-dark-mode-background-color);
    --checkbox-focus-state-invalid-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-focus-state-invalid-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-focus-state-invalid-validity-checked-value-icon-color: var(--checkbox-focus-state-invalid-validity-checked-value-light-mode-icon-color);
    --checkbox-focus-state-invalid-validity-checked-value-light-mode-background-color: var(--checkbox-hover-state-invalid-validity-checked-value-light-mode-background-color);
    --checkbox-focus-state-invalid-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-focus-state-invalid-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-focus-state-invalid-validity-indeterminate-value-background-color: var(--checkbox-focus-state-invalid-validity-indeterminate-value-light-mode-background-color);
    --checkbox-focus-state-invalid-validity-indeterminate-value-border-color: var(--checkbox-focus-state-invalid-validity-indeterminate-value-light-mode-border-color);
    --checkbox-focus-state-invalid-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-background-color);
    --checkbox-focus-state-invalid-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-focus-state-invalid-validity-indeterminate-value-light-mode-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-focus-state-invalid-validity-indeterminate-value-light-mode-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-focus-state-invalid-validity-unchecked-value-background-color: var(--checkbox-focus-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-focus-state-invalid-validity-unchecked-value-border-color: var(--checkbox-focus-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-focus-state-invalid-validity-unchecked-value-dark-mode-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-background-color);
    --checkbox-focus-state-invalid-validity-unchecked-value-dark-mode-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-focus-state-invalid-validity-unchecked-value-light-mode-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-focus-state-invalid-validity-unchecked-value-light-mode-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-focus-state-normal-validity-checked-value-background-color: var(--checkbox-focus-state-normal-validity-checked-value-light-mode-background-color);
    --checkbox-focus-state-normal-validity-checked-value-border-color: var(--checkbox-focus-state-normal-validity-checked-value-light-mode-border-color);
    --checkbox-focus-state-normal-validity-checked-value-dark-mode-background-color: var(--checkbox-hover-state-normal-validity-checked-value-dark-mode-background-color);
    --checkbox-focus-state-normal-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-focus-state-normal-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-focus-state-normal-validity-checked-value-icon-color: var(--checkbox-focus-state-normal-validity-checked-value-light-mode-icon-color);
    --checkbox-focus-state-normal-validity-checked-value-light-mode-background-color: var(--checkbox-hover-state-normal-validity-checked-value-light-mode-background-color);
    --checkbox-focus-state-normal-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-focus-state-normal-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-background-color: var(--checkbox-focus-state-normal-validity-indeterminate-value-light-mode-background-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-border-color: var(--checkbox-focus-state-normal-validity-indeterminate-value-light-mode-border-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-dark-mode-background-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-dark-mode-border-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-dark-mode-icon-color: var(--checkbox-normal-state-normal-validity-indeterminate-value-dark-mode-icon-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-icon-color: var(--checkbox-focus-state-normal-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-light-mode-background-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-light-mode-background-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-light-mode-border-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-light-mode-border-color);
    --checkbox-focus-state-normal-validity-indeterminate-value-light-mode-icon-color: var(--checkbox-normal-state-normal-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-focus-state-normal-validity-unchecked-value-background-color: var(--checkbox-focus-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-focus-state-normal-validity-unchecked-value-border-color: var(--checkbox-focus-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-focus-state-normal-validity-unchecked-value-dark-mode-background-color: var(--checkbox-hover-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-focus-state-normal-validity-unchecked-value-dark-mode-border-color: var(--checkbox-hover-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-focus-state-normal-validity-unchecked-value-light-mode-background-color: var(--checkbox-hover-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-focus-state-normal-validity-unchecked-value-light-mode-border-color: var(--checkbox-hover-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-height: var(--checkbox-width);
    --checkbox-hover-state-invalid-validity-checked-value-background-color: var(--checkbox-hover-state-invalid-validity-checked-value-light-mode-background-color);
    --checkbox-hover-state-invalid-validity-checked-value-border-color: var(--checkbox-hover-state-invalid-validity-checked-value-light-mode-border-color);
    --checkbox-hover-state-invalid-validity-checked-value-dark-mode-background-color: var(--core-critical-20-dark-mode-color);
    --checkbox-hover-state-invalid-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-hover-state-invalid-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-hover-state-invalid-validity-checked-value-icon-color: var(--checkbox-hover-state-invalid-validity-checked-value-light-mode-icon-color);
    --checkbox-hover-state-invalid-validity-checked-value-light-mode-background-color: var(--core-critical-20-light-mode-color);
    --checkbox-hover-state-invalid-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-hover-state-invalid-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-background-color: var(--checkbox-hover-state-invalid-validity-indeterminate-value-light-mode-background-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-border-color: var(--checkbox-hover-state-invalid-validity-indeterminate-value-light-mode-border-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-background-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-dark-mode-icon-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-icon-color: var(--checkbox-hover-state-invalid-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-light-mode-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-light-mode-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-hover-state-invalid-validity-indeterminate-value-light-mode-icon-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-hover-state-invalid-validity-unchecked-value-background-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-hover-state-invalid-validity-unchecked-value-border-color: var(--checkbox-hover-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-hover-state-invalid-validity-unchecked-value-dark-mode-border-color: var(--core-critical-20-dark-mode-color);
    --checkbox-hover-state-invalid-validity-unchecked-value-light-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-hover-state-invalid-validity-unchecked-value-light-mode-border-color: var(--core-critical-20-light-mode-color);
    --checkbox-hover-state-normal-validity-checked-value-background-color: var(--checkbox-hover-state-normal-validity-checked-value-light-mode-background-color);
    --checkbox-hover-state-normal-validity-checked-value-border-color: var(--checkbox-hover-state-normal-validity-checked-value-light-mode-border-color);
    --checkbox-hover-state-normal-validity-checked-value-dark-mode-background-color: var(--core-emphasis-20-dark-mode-color);
    --checkbox-hover-state-normal-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-hover-state-normal-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-hover-state-normal-validity-checked-value-icon-color: var(--checkbox-hover-state-normal-validity-checked-value-light-mode-icon-color);
    --checkbox-hover-state-normal-validity-checked-value-light-mode-background-color: var(--core-emphasis-20-light-mode-color);
    --checkbox-hover-state-normal-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-hover-state-normal-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-background-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-light-mode-background-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-border-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-light-mode-border-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-dark-mode-background-color: var(--core-emphasis-fill-dark-mode-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-hover-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-dark-mode-icon-color: var(--checkbox-hover-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-icon-color: var(--checkbox-hover-state-normal-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-light-mode-background-color: var(--checkbox-hover-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --checkbox-hover-state-normal-validity-indeterminate-value-light-mode-icon-color: var(--checkbox-hover-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-hover-state-normal-validity-unchecked-value-background-color: var(--checkbox-hover-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-hover-state-normal-validity-unchecked-value-border-color: var(--checkbox-hover-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-hover-state-normal-validity-unchecked-value-dark-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-hover-state-normal-validity-unchecked-value-dark-mode-border-color: var(--core-emphasis-fill-dark-mode-color);
    --checkbox-hover-state-normal-validity-unchecked-value-light-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-hover-state-normal-validity-unchecked-value-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --checkbox-light-mode-focus-ring-color: var(--core-focus-ring-light-mode-color);
    --checkbox-normal-state-invalid-validity-checked-value-background-color: var(--checkbox-normal-state-invalid-validity-checked-value-light-mode-background-color);
    --checkbox-normal-state-invalid-validity-checked-value-border-color: var(--checkbox-normal-state-invalid-validity-checked-value-light-mode-border-color);
    --checkbox-normal-state-invalid-validity-checked-value-dark-mode-background-color: var(--core-critical-fill-dark-mode-color);
    --checkbox-normal-state-invalid-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-normal-state-invalid-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-normal-state-invalid-validity-checked-value-icon-color: var(--checkbox-normal-state-invalid-validity-checked-value-light-mode-icon-color);
    --checkbox-normal-state-invalid-validity-checked-value-light-mode-background-color: var(--core-critical-fill-light-mode-color);
    --checkbox-normal-state-invalid-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-normal-state-invalid-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-background-color: var(--checkbox-normal-state-invalid-validity-indeterminate-value-light-mode-background-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-border-color: var(--checkbox-normal-state-invalid-validity-indeterminate-value-light-mode-border-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-dark-mode-background-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-dark-mode-icon-color: var(--core-critical-fill-dark-mode-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-icon-color: var(--checkbox-normal-state-invalid-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-light-mode-background-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-light-mode-border-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-normal-state-invalid-validity-indeterminate-value-light-mode-icon-color: var(--core-critical-fill-light-mode-color);
    --checkbox-normal-state-invalid-validity-unchecked-value-background-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-normal-state-invalid-validity-unchecked-value-border-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-normal-state-invalid-validity-unchecked-value-dark-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-normal-state-invalid-validity-unchecked-value-dark-mode-border-color: var(--core-critical-fill-dark-mode-color);
    --checkbox-normal-state-invalid-validity-unchecked-value-light-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-normal-state-invalid-validity-unchecked-value-light-mode-border-color: var(--core-critical-fill-light-mode-color);
    --checkbox-normal-state-normal-validity-checked-value-background-color: var(--checkbox-normal-state-normal-validity-checked-value-light-mode-background-color);
    --checkbox-normal-state-normal-validity-checked-value-border-color: var(--checkbox-normal-state-normal-validity-checked-value-light-mode-border-color);
    --checkbox-normal-state-normal-validity-checked-value-dark-mode-background-color: var(--core-emphasis-fill-dark-mode-color);
    --checkbox-normal-state-normal-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-normal-state-normal-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-normal-state-normal-validity-checked-value-icon-color: var(--checkbox-normal-state-normal-validity-checked-value-light-mode-icon-color);
    --checkbox-normal-state-normal-validity-checked-value-light-mode-background-color: var(--core-emphasis-fill-light-mode-color);
    --checkbox-normal-state-normal-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-normal-state-normal-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-background-color: var(--checkbox-normal-state-normal-validity-indeterminate-value-light-mode-background-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-border-color: var(--checkbox-normal-state-normal-validity-indeterminate-value-light-mode-border-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-dark-mode-icon-color: var(--core-emphasis-fill-dark-mode-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-icon-color: var(--checkbox-normal-state-normal-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-light-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-light-mode-border-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-normal-state-normal-validity-indeterminate-value-light-mode-icon-color: var(--core-emphasis-fill-light-mode-color);
    --checkbox-normal-state-normal-validity-unchecked-value-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-normal-state-normal-validity-unchecked-value-border-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color: transparent;
    --checkbox-normal-state-normal-validity-unchecked-value-dark-mode-border-color: var(--core-fill-20-dark-mode-color);
    --checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color: transparent;
    --checkbox-normal-state-normal-validity-unchecked-value-light-mode-border-color: var(--core-fill-20-light-mode-color);
    --checkbox-pressed-state-invalid-validity-checked-value-background-color: var(--checkbox-pressed-state-invalid-validity-checked-value-light-mode-background-color);
    --checkbox-pressed-state-invalid-validity-checked-value-border-color: var(--checkbox-pressed-state-invalid-validity-checked-value-light-mode-border-color);
    --checkbox-pressed-state-invalid-validity-checked-value-dark-mode-background-color: var(--core-critical-10-dark-mode-color);
    --checkbox-pressed-state-invalid-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-pressed-state-invalid-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-pressed-state-invalid-validity-checked-value-icon-color: var(--checkbox-pressed-state-invalid-validity-checked-value-light-mode-icon-color);
    --checkbox-pressed-state-invalid-validity-checked-value-light-mode-background-color: var(--core-critical-10-light-mode-color);
    --checkbox-pressed-state-invalid-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-pressed-state-invalid-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-background-color: var(--checkbox-pressed-state-invalid-validity-indeterminate-value-light-mode-background-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-border-color: var(--checkbox-pressed-state-invalid-validity-indeterminate-value-light-mode-border-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-dark-mode-background-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-pressed-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-dark-mode-icon-color: var(--checkbox-pressed-state-invalid-validity-unchecked-value-dark-mode-border-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-icon-color: var(--checkbox-pressed-state-invalid-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-light-mode-background-color: var(--checkbox-normal-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-light-mode-border-color: var(--checkbox-pressed-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-pressed-state-invalid-validity-indeterminate-value-light-mode-icon-color: var(--checkbox-pressed-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-pressed-state-invalid-validity-unchecked-value-background-color: var(--checkbox-pressed-state-invalid-validity-unchecked-value-light-mode-background-color);
    --checkbox-pressed-state-invalid-validity-unchecked-value-border-color: var(--checkbox-pressed-state-invalid-validity-unchecked-value-light-mode-border-color);
    --checkbox-pressed-state-invalid-validity-unchecked-value-dark-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-pressed-state-invalid-validity-unchecked-value-dark-mode-border-color: var(--core-critical-10-dark-mode-color);
    --checkbox-pressed-state-invalid-validity-unchecked-value-light-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-pressed-state-invalid-validity-unchecked-value-light-mode-border-color: var(--core-critical-10-light-mode-color);
    --checkbox-pressed-state-normal-validity-checked-value-background-color: var(--checkbox-pressed-state-normal-validity-checked-value-light-mode-background-color);
    --checkbox-pressed-state-normal-validity-checked-value-border-color: var(--checkbox-pressed-state-normal-validity-checked-value-light-mode-border-color);
    --checkbox-pressed-state-normal-validity-checked-value-dark-mode-background-color: var(--core-emphasis-10-dark-mode-color);
    --checkbox-pressed-state-normal-validity-checked-value-dark-mode-border-color: transparent;
    --checkbox-pressed-state-normal-validity-checked-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --checkbox-pressed-state-normal-validity-checked-value-icon-color: var(--checkbox-pressed-state-normal-validity-checked-value-light-mode-icon-color);
    --checkbox-pressed-state-normal-validity-checked-value-light-mode-background-color: var(--core-emphasis-10-light-mode-color);
    --checkbox-pressed-state-normal-validity-checked-value-light-mode-border-color: transparent;
    --checkbox-pressed-state-normal-validity-checked-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-background-color: var(--checkbox-pressed-state-normal-validity-indeterminate-value-light-mode-background-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-border-color: var(--checkbox-pressed-state-normal-validity-indeterminate-value-light-mode-border-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-dark-mode-background-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-dark-mode-border-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-dark-mode-icon-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-dark-mode-border-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-icon-color: var(--checkbox-pressed-state-normal-validity-indeterminate-value-light-mode-icon-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-light-mode-background-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-light-mode-border-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-pressed-state-normal-validity-indeterminate-value-light-mode-icon-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-pressed-state-normal-validity-unchecked-value-background-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-pressed-state-normal-validity-unchecked-value-border-color: var(--checkbox-pressed-state-normal-validity-unchecked-value-light-mode-border-color);
    --checkbox-pressed-state-normal-validity-unchecked-value-dark-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-dark-mode-background-color);
    --checkbox-pressed-state-normal-validity-unchecked-value-dark-mode-border-color: var(--core-emphasis-10-dark-mode-color);
    --checkbox-pressed-state-normal-validity-unchecked-value-light-mode-background-color: var(--checkbox-normal-state-normal-validity-unchecked-value-light-mode-background-color);
    --checkbox-pressed-state-normal-validity-unchecked-value-light-mode-border-color: var(--core-emphasis-10-light-mode-color);
    --checkbox-width: 20px;
    --choice-block-set-spacing: 16px;
    --choice-block-border-color: var(--choice-block-border-light-mode-color);
    --choice-block-border-dark-mode-color: var(--core-divider-20-dark-mode-color);
    --choice-block-border-light-mode-color: var(--core-divider-20-light-mode-color);
    --choice-block-border-radius: 6px;
    --choice-block-border-size: 1px;
    --choice-block-dark-mode-focus-ring-color: var(--core-focus-ring-dark-mode-color);
    --choice-block-drill-variant-padding-horizontal-size: var(--choice-block-small-layout-size-drill-variant-padding-horizontal-size);
    --choice-block-focus-ring-border-size: var(--core-focus-ring-border-size);
    --choice-block-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --choice-block-focus-ring-color: var(--choice-block-light-mode-focus-ring-color);
    --choice-block-label-weight: var(--core-type-medium-weight);
    --choice-block-large-layout-size-drill-variant-padding-horizontal-size: var(--choice-block-small-layout-size-drill-variant-padding-horizontal-size);
    --choice-block-large-layout-size-minimum-height: var(--choice-block-small-layout-size-minimum-height);
    --choice-block-large-layout-size-padding-vertical-size: var(--choice-block-small-layout-size-padding-vertical-size);
    --choice-block-large-layout-size-regular-variant-padding-horizontal-size: var(--choice-block-small-layout-size-regular-variant-padding-horizontal-size);
    --choice-block-light-mode-focus-ring-color: var(--core-focus-ring-light-mode-color);
    --choice-block-medium-layout-size-drill-variant-padding-horizontal-size: var(--choice-block-small-layout-size-drill-variant-padding-horizontal-size);
    --choice-block-medium-layout-size-minimum-height: var(--choice-block-small-layout-size-minimum-height);
    --choice-block-medium-layout-size-padding-vertical-size: var(--choice-block-small-layout-size-padding-vertical-size);
    --choice-block-medium-layout-size-regular-variant-padding-horizontal-size: var(--choice-block-small-layout-size-regular-variant-padding-horizontal-size);
    --choice-block-minimum-height: var(--choice-block-small-layout-size-minimum-height);
    --choice-block-padding-vertical-size: var(--choice-block-small-layout-size-padding-vertical-size);
    --choice-block-regular-variant-padding-horizontal-size: var(--choice-block-small-layout-size-regular-variant-padding-horizontal-size);
    --choice-block-small-layout-size-drill-variant-padding-horizontal-size: 16px;
    --choice-block-small-layout-size-minimum-height: 80px;
    --choice-block-small-layout-size-padding-vertical-size: 15px;
    --choice-block-small-layout-size-regular-variant-padding-horizontal-size: 0px;
    --choice-block-subtext-padding-vertical-size: 2px;
    --content-card-background-color: var(--content-card-background-light-mode-color);
    --content-card-background-dark-mode-color: rgba(20,20,20,1);
    --content-card-background-light-mode-color: rgba(255,255,255,1);
    --content-card-border-color: var(--content-card-border-light-mode-color);
    --content-card-border-dark-mode-color: rgba(255,255,255,0.15);
    --content-card-border-light-mode-color: rgba(0,0,0,0.15);
    --content-card-border-radius: 6px;
    --content-card-border-width: 1px;
    --content-card-padding-horizontal-size: 16px;
    --content-card-padding-vertical-size: 16px;
    --field-action-text-case: regular;
    --field-action-text-color: var(--field-light-mode-action-text-color);
    --field-action-text-large-layout-size-leading: var(--core-type-emphasis-20-medium-layout-size-leading);
    --field-action-text-large-layout-size-size: var(--core-type-emphasis-20-medium-layout-size-size);
    --field-action-text-large-layout-size-tracking: var(--core-type-emphasis-20-medium-layout-size-tracking);
    --field-action-text-leading: var(--field-action-text-small-layout-size-leading);
    --field-action-text-medium-layout-size-leading: var(--core-type-emphasis-20-small-layout-size-leading);
    --field-action-text-medium-layout-size-size: var(--core-type-emphasis-20-small-layout-size-size);
    --field-action-text-medium-layout-size-tracking: var(--core-type-emphasis-20-small-layout-size-tracking);
    --field-action-text-scale: paragraph-20;
    --field-action-text-size: var(--field-action-text-small-layout-size-size);
    --field-action-text-small-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --field-action-text-small-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --field-action-text-small-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --field-action-text-tracking: var(--field-action-text-small-layout-size-tracking);
    --field-action-text-weight: var(--core-type-medium-weight);
    --field-action-vertical-padding-size: 8px;
    --field-border-radius: 6px;
    --field-border-size: 1px;
    --field-dark-mode-action-text-color: var(--core-emphasis-fill-dark-mode-color);
    --field-dark-mode-error-message-text-color: var(--core-critical-text-dark-mode-color);
    --field-dark-mode-helper-text-text-color: var(--core-text-20-dark-mode-color);
    --field-dark-mode-placeholder-text-color: var(--core-text-30-dark-mode-color);
    --field-disabled-state-background-color: var(--field-disabled-state-light-mode-background-color);
    --field-disabled-state-border-color: var(--field-disabled-state-light-mode-border-color);
    --field-disabled-state-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --field-disabled-state-dark-mode-border-color: var(--core-fill-40-dark-mode-color);
    --field-disabled-state-dark-mode-input-color: var(--field-disabled-state-empty-phase-dark-mode-label-color);
    --field-disabled-state-empty-phase-dark-mode-label-color: var(--core-text-30-dark-mode-color);
    --field-disabled-state-empty-phase-label-color: var(--field-disabled-state-empty-phase-light-mode-label-color);
    --field-disabled-state-empty-phase-light-mode-label-color: var(--core-text-30-light-mode-color);
    --field-disabled-state-float-phase-dark-mode-label-color: var(--core-text-30-dark-mode-color);
    --field-disabled-state-float-phase-label-color: var(--field-disabled-state-float-phase-light-mode-label-color);
    --field-disabled-state-float-phase-light-mode-label-color: var(--core-text-30-light-mode-color);
    --field-disabled-state-input-color: var(--field-disabled-state-light-mode-input-color);
    --field-disabled-state-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --field-disabled-state-light-mode-border-color: var(--core-fill-40-light-mode-color);
    --field-disabled-state-light-mode-input-color: var(--field-disabled-state-empty-phase-light-mode-label-color);
    --field-empty-phase-horizontal-padding-size: 16px;
    --field-empty-phase-label-text-case: regular;
    --field-empty-phase-label-text-large-layout-size-leading: var(--core-type-paragraph-30-medium-layout-size-leading);
    --field-empty-phase-label-text-large-layout-size-size: var(--core-type-paragraph-30-medium-layout-size-size);
    --field-empty-phase-label-text-large-layout-size-tracking: var(--core-type-paragraph-30-medium-layout-size-tracking);
    --field-empty-phase-label-text-leading: var(--field-empty-phase-label-text-small-layout-size-leading);
    --field-empty-phase-label-text-medium-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --field-empty-phase-label-text-medium-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --field-empty-phase-label-text-medium-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --field-empty-phase-label-text-scale: paragraph-30;
    --field-empty-phase-label-text-size: var(--field-empty-phase-label-text-small-layout-size-size);
    --field-empty-phase-label-text-small-layout-size-leading: 24px;
    --field-empty-phase-label-text-small-layout-size-size: 16px;
    --field-empty-phase-label-text-small-layout-size-tracking: 0;
    --field-empty-phase-label-text-tracking: var(--field-empty-phase-label-text-small-layout-size-tracking);
    --field-empty-phase-label-text-weight: var(--core-type-regular-weight);
    --field-empty-phase-vertical-standard-layout-size-padding-size: 20px;
    --field-error-message-icon-buffer-size: 8px;
    --field-error-message-icon-height: 8px;
    --field-error-message-icon-vertical-large-layout-size-spacing: var(--field-error-message-icon-vertical-medium-layout-size-spacing);
    --field-error-message-icon-vertical-medium-layout-size-spacing: var(--field-error-message-icon-vertical-small-layout-size-spacing);
    --field-error-message-icon-vertical-small-layout-size-spacing: 8px;
    --field-error-message-icon-vertical-spacing: var(--field-error-message-icon-vertical-small-layout-size-spacing);
    --field-error-message-icon-width: var(--field-error-message-icon-height);
    --field-error-message-text-case: regular;
    --field-error-message-text-color: var(--field-light-mode-error-message-text-color);
    --field-error-message-text-large-layout-size-leading: var(--core-type-paragraph-20-medium-layout-size-leading);
    --field-error-message-text-large-layout-size-size: var(--core-type-paragraph-20-medium-layout-size-size);
    --field-error-message-text-large-layout-size-tracking: var(--core-type-paragraph-20-medium-layout-size-tracking);
    --field-error-message-text-leading: var(--field-error-message-text-small-layout-size-leading);
    --field-error-message-text-medium-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --field-error-message-text-medium-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --field-error-message-text-medium-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --field-error-message-text-scale: paragraph-20;
    --field-error-message-text-size: var(--field-error-message-text-small-layout-size-size);
    --field-error-message-text-small-layout-size-leading: 22px;
    --field-error-message-text-small-layout-size-size: 14px;
    --field-error-message-text-small-layout-size-tracking: 0;
    --field-error-message-text-tracking: var(--field-error-message-text-small-layout-size-tracking);
    --field-error-message-text-vertical-large-layout-size-spacing: var(--field-error-message-text-vertical-medium-layout-size-spacing);
    --field-error-message-text-vertical-medium-layout-size-spacing: var(--field-error-message-text-vertical-small-layout-size-spacing);
    --field-error-message-text-vertical-small-layout-size-spacing: 8px;
    --field-error-message-text-vertical-spacing: var(--field-error-message-text-vertical-small-layout-size-spacing);
    --field-error-message-text-weight: var(--core-type-regular-weight);
    --field-float-phase-horizontal-padding-size: var(--field-empty-phase-horizontal-padding-size);
    --field-float-phase-label-margin-bottom-size: 2px;
    --field-float-phase-label-margin-top-size: 8px;
    --field-float-phase-label-text-case: regular;
    --field-float-phase-label-text-large-layout-size-leading: var(--core-type-emphasis-20-medium-layout-size-leading);
    --field-float-phase-label-text-large-layout-size-size: var(--core-type-emphasis-20-medium-layout-size-size);
    --field-float-phase-label-text-large-layout-size-tracking: var(--core-type-emphasis-20-medium-layout-size-tracking);
    --field-float-phase-label-text-leading: var(--field-float-phase-label-text-small-layout-size-leading);
    --field-float-phase-label-text-medium-layout-size-leading: var(--core-type-emphasis-20-small-layout-size-leading);
    --field-float-phase-label-text-medium-layout-size-size: var(--core-type-emphasis-20-small-layout-size-size);
    --field-float-phase-label-text-medium-layout-size-tracking: var(--core-type-emphasis-20-small-layout-size-tracking);
    --field-float-phase-label-text-scale: paragraph-20;
    --field-float-phase-label-text-size: var(--field-float-phase-label-text-small-layout-size-size);
    --field-float-phase-label-text-small-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --field-float-phase-label-text-small-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --field-float-phase-label-text-small-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --field-float-phase-label-text-tracking: var(--field-float-phase-label-text-small-layout-size-tracking);
    --field-float-phase-label-text-weight: 500;
    --field-float-phase-vertical-standard-layout-size-padding-size: 8px;
    --field-focus-state-background-color: var(--field-focus-state-light-mode-background-color);
    --field-focus-state-border-size: 2px;
    --field-focus-state-dark-mode-background-color: var(--field-normal-state-dark-mode-background-color);
    --field-focus-state-dark-mode-input-color: var(--field-normal-state-dark-mode-input-color);
    --field-focus-state-float-phase-dark-mode-label-color: var(--field-normal-state-float-phase-dark-mode-label-color);
    --field-focus-state-float-phase-label-color: var(--field-focus-state-float-phase-light-mode-label-color);
    --field-focus-state-float-phase-light-mode-label-color: var(--field-normal-state-float-phase-light-mode-label-color);
    --field-focus-state-input-color: var(--field-focus-state-light-mode-input-color);
    --field-focus-state-invalid-validity-border-color: var(--field-focus-state-invalid-validity-light-mode-border-color);
    --field-focus-state-invalid-validity-dark-mode-border-color: var(--field-focus-state-normal-validity-dark-mode-border-color);
    --field-focus-state-invalid-validity-light-mode-border-color: var(--field-focus-state-normal-validity-light-mode-border-color);
    --field-focus-state-light-mode-background-color: var(--field-normal-state-light-mode-background-color);
    --field-focus-state-light-mode-input-color: var(--field-normal-state-light-mode-input-color);
    --field-focus-state-normal-validity-border-color: var(--field-focus-state-normal-validity-light-mode-border-color);
    --field-focus-state-normal-validity-dark-mode-border-color: var(--core-emphasis-fill-dark-mode-color);
    --field-focus-state-normal-validity-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --field-helper-text-case: regular;
    --field-helper-text-horizontal-spacing: 0px;
    --field-helper-text-large-layout-size-leading: var(--core-type-paragraph-20-medium-layout-size-leading);
    --field-helper-text-large-layout-size-size: var(--core-type-paragraph-20-medium-layout-size-size);
    --field-helper-text-large-layout-size-tracking: var(--core-type-paragraph-20-medium-layout-size-tracking);
    --field-helper-text-leading: var(--field-helper-text-small-layout-size-leading);
    --field-helper-text-medium-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --field-helper-text-medium-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --field-helper-text-medium-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --field-helper-text-scale: paragraph-20;
    --field-helper-text-size: var(--field-helper-text-small-layout-size-size);
    --field-helper-text-small-layout-size-leading: 22px;
    --field-helper-text-small-layout-size-size: 14px;
    --field-helper-text-small-layout-size-tracking: 0;
    --field-helper-text-text-color: var(--field-light-mode-helper-text-text-color);
    --field-helper-text-tracking: var(--field-helper-text-small-layout-size-tracking);
    --field-helper-text-vertical-standard-layout-size-spacing: 8px;
    --field-helper-text-weight: var(--core-type-regular-weight);
    --field-hover-state-background-color: var(--field-hover-state-light-mode-background-color);
    --field-hover-state-dark-mode-background-color: var(--field-normal-state-dark-mode-background-color);
    --field-hover-state-dark-mode-input-color: var(--field-normal-state-dark-mode-input-color);
    --field-hover-state-empty-phase-dark-mode-label-color: var(--field-normal-state-empty-phase-dark-mode-label-color);
    --field-hover-state-empty-phase-label-color: var(--field-hover-state-empty-phase-light-mode-label-color);
    --field-hover-state-empty-phase-light-mode-label-color: var(--field-normal-state-empty-phase-light-mode-label-color);
    --field-hover-state-float-phase-dark-mode-label-color: var(--field-normal-state-float-phase-dark-mode-label-color);
    --field-hover-state-float-phase-label-color: var(--field-hover-state-float-phase-light-mode-label-color);
    --field-hover-state-float-phase-light-mode-label-color: var(--field-normal-state-float-phase-light-mode-label-color);
    --field-hover-state-input-color: var(--field-hover-state-light-mode-input-color);
    --field-hover-state-invalid-validity-border-color: var(--field-hover-state-invalid-validity-light-mode-border-color);
    --field-hover-state-invalid-validity-dark-mode-border-color: var(--field-hover-state-normal-validity-dark-mode-border-color);
    --field-hover-state-invalid-validity-light-mode-border-color: var(--field-hover-state-normal-validity-light-mode-border-color);
    --field-hover-state-light-mode-background-color: var(--field-normal-state-light-mode-background-color);
    --field-hover-state-light-mode-input-color: var(--field-normal-state-light-mode-input-color);
    --field-hover-state-normal-validity-border-color: var(--field-hover-state-normal-validity-light-mode-border-color);
    --field-hover-state-normal-validity-dark-mode-border-color: var(--core-emphasis-fill-dark-mode-color);
    --field-hover-state-normal-validity-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --field-icon-buffer-size: 16px;
    --field-icon-height: 24px;
    --field-icon-width: var(--field-icon-height);
    --field-image-accessory-spacing-left-size: 12px;
    --field-input-case: regular;
    --field-input-large-layout-size-leading: var(--core-type-paragraph-30-medium-layout-size-leading);
    --field-input-large-layout-size-size: var(--core-type-paragraph-30-medium-layout-size-size);
    --field-input-large-layout-size-tracking: var(--core-type-paragraph-30-medium-layout-size-tracking);
    --field-input-leading: var(--field-input-small-layout-size-leading);
    --field-input-medium-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --field-input-medium-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --field-input-medium-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --field-input-scale: paragraph-30;
    --field-input-size: var(--field-input-small-layout-size-size);
    --field-input-small-layout-size-leading: 24px;
    --field-input-small-layout-size-size: 16px;
    --field-input-small-layout-size-tracking: 0;
    --field-input-tracking: var(--field-input-small-layout-size-tracking);
    --field-input-weight: var(--core-type-regular-weight);
    --field-light-mode-action-text-color: var(--core-emphasis-fill-light-mode-color);
    --field-light-mode-error-message-text-color: var(--core-critical-text-light-mode-color);
    --field-light-mode-helper-text-text-color: var(--core-text-20-light-mode-color);
    --field-light-mode-placeholder-text-color: var(--core-text-30-light-mode-color);
    --field-normal-state-background-color: var(--field-normal-state-light-mode-background-color);
    --field-normal-state-dark-mode-background-color: var(--core-surface-5-dark-mode-color);
    --field-normal-state-dark-mode-input-color: var(--core-text-10-dark-mode-color);
    --field-normal-state-empty-phase-dark-mode-label-color: var(--core-text-20-dark-mode-color);
    --field-normal-state-empty-phase-label-color: var(--field-normal-state-empty-phase-light-mode-label-color);
    --field-normal-state-empty-phase-light-mode-label-color: var(--core-text-20-light-mode-color);
    --field-normal-state-float-phase-dark-mode-label-color: var(--core-text-10-dark-mode-color);
    --field-normal-state-float-phase-label-color: var(--field-normal-state-float-phase-light-mode-label-color);
    --field-normal-state-float-phase-light-mode-label-color: var(--core-text-10-light-mode-color);
    --field-normal-state-input-color: var(--field-normal-state-light-mode-input-color);
    --field-normal-state-invalid-validity-border-color: var(--field-normal-state-invalid-validity-light-mode-border-color);
    --field-normal-state-invalid-validity-dark-mode-border-color: var(--core-critical-fill-dark-mode-color);
    --field-normal-state-invalid-validity-light-mode-border-color: var(--core-critical-fill-light-mode-color);
    --field-normal-state-light-mode-background-color: var(--core-surface-5-light-mode-color);
    --field-normal-state-light-mode-input-color: var(--core-text-10-light-mode-color);
    --field-normal-state-normal-validity-border-color: var(--field-normal-state-normal-validity-light-mode-border-color);
    --field-normal-state-normal-validity-dark-mode-border-color: var(--core-fill-30-dark-mode-color);
    --field-normal-state-normal-validity-light-mode-border-color: var(--core-fill-30-light-mode-color);
    --field-placeholder-text-color: var(--field-light-mode-placeholder-text-color);
    --field-trailing-accessory-buffer-size: 16px;
    --field-trailing-accessory-height: 48px;
    --field-trailing-accessory-position-bottom-size: 8px;
    --field-trailing-accessory-position-right-size: 0px;
    --field-trailing-accessory-width: var(--field-trailing-accessory-height);
    --footer-vertical-large-layout-size-padding-size: 16px;
    --footer-vertical-medium-layout-size-padding-size: var(--footer-vertical-large-layout-size-padding-size);
    --footer-vertical-padding-size: var(--footer-vertical-small-layout-size-padding-size);
    --footer-vertical-small-layout-size-padding-size: var(--footer-vertical-medium-layout-size-padding-size);
    --header-bottom-large-layout-size-padding-size: 16px;
    --header-bottom-medium-layout-size-padding-size: var(--header-bottom-large-layout-size-padding-size);
    --header-bottom-padding-size: var(--header-bottom-small-layout-size-padding-size);
    --header-bottom-small-layout-size-padding-size: var(--header-bottom-medium-layout-size-padding-size);
    --header-horizontal-large-layout-size-padding-size: 16px;
    --header-horizontal-medium-layout-size-padding-size: var(--header-horizontal-large-layout-size-padding-size);
    --header-horizontal-padding-size: var(--header-horizontal-small-layout-size-padding-size);
    --header-horizontal-small-layout-size-padding-size: var(--header-horizontal-medium-layout-size-padding-size);
    --header-main-text-dark-mode-text-color: var(--core-text-10-dark-mode-color);
    --header-main-text-horizontal-spacing: 16px;
    --header-main-text-light-mode-text-color: var(--core-text-10-light-mode-color);
    --header-main-text-normal-phase-spacing-top-size: 8px;
    --header-main-text-text-color: var(--header-main-text-light-mode-text-color);
    --header-nav-icon-button-spacing-buttom-size: 24px;
    --header-sub-text-dark-mode-text-color: var(--core-text-20-dark-mode-color);
    --header-sub-text-light-mode-text-color: var(--core-text-20-light-mode-color);
    --header-sub-text-spacing-bottom-size: 8px;
    --header-sub-text-text-color: var(--header-sub-text-light-mode-text-color);
    --header-top-large-layout-size-padding-size: 24px;
    --header-top-medium-layout-size-padding-size: var(--header-top-large-layout-size-padding-size);
    --header-top-padding-size: var(--header-top-small-layout-size-padding-size);
    --header-top-small-layout-size-padding-size: var(--header-top-medium-layout-size-padding-size);
    --icon-button-border-radius: var(--button-border-radius);
    --icon-button-compact-size-minimum-height: 40px;
    --icon-button-compact-size-minimum-padding-size: 12px;
    --icon-button-compact-size-minimum-width: 40px;
    --icon-button-compact-size-padding-size: 12px;
    --icon-button-disabled-state-background-color: var(--icon-button-disabled-state-light-mode-background-color);
    --icon-button-disabled-state-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --icon-button-disabled-state-dark-mode-icon-color: var(--core-text-30-dark-mode-color);
    --icon-button-disabled-state-icon-color: var(--icon-button-disabled-state-light-mode-icon-color);
    --icon-button-disabled-state-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --icon-button-disabled-state-light-mode-icon-color: var(--core-text-30-light-mode-color);
    --icon-button-focus-ring-border-size: var(--core-focus-ring-border-size);
    --icon-button-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --icon-button-focus-state-background-color: var(--icon-button-focus-state-light-mode-background-color);
    --icon-button-focus-state-dark-mode-background-color: var(--icon-button-hover-state-dark-mode-background-color);
    --icon-button-focus-state-dark-mode-icon-color: var(--icon-button-hover-state-dark-mode-icon-color);
    --icon-button-focus-state-icon-color: var(--icon-button-focus-state-light-mode-icon-color);
    --icon-button-focus-state-light-mode-background-color: var(--icon-button-hover-state-light-mode-background-color);
    --icon-button-focus-state-light-mode-icon-color: var(--icon-button-hover-state-light-mode-icon-color);
    --icon-button-hover-state-background-color: var(--icon-button-hover-state-light-mode-background-color);
    --icon-button-hover-state-dark-mode-background-color: var(--core-emphasis-40-dark-mode-color);
    --icon-button-hover-state-dark-mode-icon-color: var(--core-text-10-dark-mode-color);
    --icon-button-hover-state-icon-color: var(--icon-button-hover-state-light-mode-icon-color);
    --icon-button-hover-state-light-mode-background-color: var(--core-emphasis-40-light-mode-color);
    --icon-button-hover-state-light-mode-icon-color: var(--core-text-10-light-mode-color);
    --icon-button-minimum-height: 48px;
    --icon-button-minimum-width: 48px;
    --icon-button-normal-size-minimum-height: 48px;
    --icon-button-normal-size-minimum-width: 48px;
    --icon-button-normal-size-padding-size: 16px;
    --icon-button-normal-state-background-color: var(--icon-button-normal-state-light-mode-background-color);
    --icon-button-normal-state-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --icon-button-normal-state-dark-mode-icon-color: var(--core-text-10-dark-mode-color);
    --icon-button-normal-state-icon-color: var(--icon-button-normal-state-light-mode-icon-color);
    --icon-button-normal-state-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --icon-button-normal-state-light-mode-icon-color: var(--core-text-10-light-mode-color);
    --icon-button-padding-size: 16px;
    --icon-button-pressed-state-background-color: var(--icon-button-pressed-state-light-mode-background-color);
    --icon-button-pressed-state-dark-mode-background-color: var(--core-emphasis-30-dark-mode-color);
    --icon-button-pressed-state-dark-mode-icon-color: var(--core-text-10-dark-mode-color);
    --icon-button-pressed-state-icon-color: var(--icon-button-pressed-state-light-mode-icon-color);
    --icon-button-pressed-state-light-mode-background-color: var(--core-emphasis-30-light-mode-color);
    --icon-button-pressed-state-light-mode-icon-color: var(--core-text-10-light-mode-color);
    --modal-dialog-animation-duration: 0.50s;
    --modal-dialog-background-color: var(--modal-dialog-light-mode-background-color);
    --modal-dialog-border-radius: 12px;
    --modal-dialog-dark-mode-background-color: var(--core-surface-20-dark-mode-color);
    --modal-dialog-light-mode-background-color: var(--core-surface-20-light-mode-color);
    --modal-dialog-medium-viewport-horizontal-padding-size: 40px;
    --modal-dialog-medium-viewport-vertical-padding-size: 24px;
    --modal-dialog-medium-viewport-width: 480px;
    --modal-dialog-narrow-viewport-horizontal-padding-size: 24px;
    --modal-dialog-narrow-viewport-vertical-padding-size: 24px;
    --modal-dialog-wide-viewport-horizontal-padding-size: 40px;
    --modal-dialog-wide-viewport-vertical-padding-size: 24px;
    --modal-dialog-wide-viewport-width: 480px;
    --modal-full-background-color: var(--modal-full-light-mode-background-color);
    --modal-full-dark-mode-background-color: var(--core-surface-5-dark-mode-color);
    --modal-full-light-mode-background-color: var(--core-surface-5-light-mode-color);
    --modal-full-medium-viewport-animation-duration: var(--modal-full-narrow-viewport-animation-duration);
    --modal-full-medium-viewport-horizontal-padding-size: 24px;
    --modal-full-medium-viewport-vertical-padding-size: 16px;
    --modal-full-narrow-viewport-animation-duration: 0.50s;
    --modal-full-narrow-viewport-horizontal-padding-size: 16px;
    --modal-full-narrow-viewport-vertical-padding-size: 16px;
    --modal-full-wide-viewport-animation-duration: var(--modal-full-medium-viewport-animation-duration);
    --modal-full-wide-viewport-horizontal-padding-size: 40px;
    --modal-full-wide-viewport-vertical-padding-size: 16px;
    --modal-partial-background-color: var(--modal-partial-light-mode-background-color);
    --modal-partial-border-radius: 12px;
    --modal-partial-dark-mode-background-color: var(--core-surface-5-dark-mode-color);
    --modal-partial-light-mode-background-color: var(--core-surface-5-light-mode-color);
    --modal-partial-medium-viewport-animation-duration: var(--modal-partial-narrow-viewport-animation-duration);
    --modal-partial-medium-viewport-horizontal-padding-size: 24px;
    --modal-partial-medium-viewport-horizontal-screen-buffer-size: 24px;
    --modal-partial-medium-viewport-vertical-padding-size: 16px;
    --modal-partial-medium-viewport-vertical-screen-buffer-size: 24px;
    --modal-partial-narrow-viewport-animation-duration: 0.50s;
    --modal-partial-narrow-viewport-horizontal-padding-size: 16px;
    --modal-partial-narrow-viewport-horizontal-screen-buffer-size: 0px;
    --modal-partial-narrow-viewport-vertical-padding-size: 16px;
    --modal-partial-narrow-viewport-vertical-screen-buffer-size: 0px;
    --modal-partial-wide-viewport-animation-duration: var(--modal-partial-medium-viewport-animation-duration);
    --modal-partial-wide-viewport-horizontal-padding-size: 40px;
    --modal-partial-wide-viewport-horizontal-screen-buffer-size: 24px;
    --modal-partial-wide-viewport-vertical-padding-size: 40px;
    --modal-partial-wide-viewport-vertical-screen-buffer-size: 24px;
    --modal-popover-appear-animation-percentage: 70px;
    --modal-popover-background-color: var(--core-surface-30-light-mode-color);
    --modal-popover-border-radius: 6px;
    --modal-popover-wide-viewport-max-height-size: 500px;
    --modal-popover-wide-viewport-min-width-size: 200px;
    --modal-popover-wide-viewport-padding-bottom-size: 24px;
    --modal-popover-wide-viewport-padding-left-size: 24px;
    --modal-popover-wide-viewport-padding-right-size: 24px;
    --modal-popover-wide-viewport-padding-top-size: 8px;
    --modal-sheet-background-color: var(--core-surface-20-light-mode-color);
    --modal-sheet-border-radius: 12px;
    --modal-sheet-handle-background-color: var(--core-fill-30-light-mode-color);
    --modal-sheet-handle-border-radius: 2px;
    --modal-sheet-handle-height: 4px;
    --modal-sheet-handle-top-buffer-size: 10px;
    --modal-sheet-handle-width: 40px;
    --modal-sheet-narrow-viewport-padding-bottom-size: 24px;
    --modal-sheet-narrow-viewport-padding-left-size: 16px;
    --modal-sheet-narrow-viewport-padding-right-size: 16px;
    --modal-sheet-narrow-viewport-padding-top-size: 24px;
    --modal-sheet-padding-bottom-size: var(--modal-sheet-narrow-viewport-padding-bottom-size);
    --modal-sheet-padding-left-size: var(--modal-sheet-narrow-viewport-padding-left-size);
    --modal-sheet-padding-right-size: var(--modal-sheet-narrow-viewport-padding-right-size);
    --modal-sheet-padding-top-size: var(--modal-sheet-narrow-viewport-padding-top-size);
    --modal-sheet-wide-viewport-padding-bottom-size: 24px;
    --modal-sheet-wide-viewport-padding-left-size: 40px;
    --modal-sheet-wide-viewport-padding-right-size: 40px;
    --modal-sheet-wide-viewport-padding-top-size: 24px;
    --page-indicator-dark-mode-dot-color: var(--core-text-20-dark-mode-color);
    --page-indicator-dark-mode-selected-color: var(--core-emphasis-fill-dark-mode-color);
    --page-indicator-dot-color: var(--page-indicator-light-mode-dot-color);
    --page-indicator-dot-size: var(--page-indicator-small-layout-size-dot-size);
    --page-indicator-large-layout-size-dot-size: 8px;
    --page-indicator-large-layout-size-selected-size: 12px;
    --page-indicator-large-layout-size-spacing-size: 8px;
    --page-indicator-light-mode-dot-color: var(--core-text-20-light-mode-color);
    --page-indicator-light-mode-selected-color: var(--core-emphasis-fill-light-mode-color);
    --page-indicator-medium-layout-size-dot-size: 6px;
    --page-indicator-medium-layout-size-selected-size: 8px;
    --page-indicator-medium-layout-size-spacing-size: 6px;
    --page-indicator-selected-color: var(--page-indicator-light-mode-selected-color);
    --page-indicator-selected-size: var(--page-indicator-small-layout-size-selected-size);
    --page-indicator-small-layout-size-dot-size: 6px;
    --page-indicator-small-layout-size-selected-size: 8px;
    --page-indicator-small-layout-size-spacing-size: 6px;
    --page-indicator-spacing-size: var(--page-indicator-small-layout-size-spacing-size);
    --radio-border-radius: 100px;
    --radio-border-size: 2px;
    --radio-dark-mode-focus-ring-color: var(--core-focus-ring-dark-mode-color);
    --radio-disabled-state-normal-validity-selected-value-background-color: var(--radio-disabled-state-normal-validity-selected-value-light-mode-background-color);
    --radio-disabled-state-normal-validity-selected-value-border-color: var(--radio-disabled-state-normal-validity-selected-value-light-mode-border-color);
    --radio-disabled-state-normal-validity-selected-value-dark-mode-background-color: var(--core-fill-30-dark-mode-color);
    --radio-disabled-state-normal-validity-selected-value-dark-mode-border-color: transparent;
    --radio-disabled-state-normal-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-disabled-state-normal-validity-selected-value-icon-color: var(--radio-disabled-state-normal-validity-selected-value-light-mode-icon-color);
    --radio-disabled-state-normal-validity-selected-value-light-mode-background-color: var(--core-fill-30-light-mode-color);
    --radio-disabled-state-normal-validity-selected-value-light-mode-border-color: transparent;
    --radio-disabled-state-normal-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-disabled-state-normal-validity-unselected-value-background-color: var(--radio-disabled-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-disabled-state-normal-validity-unselected-value-border-color: var(--radio-disabled-state-normal-validity-unselected-value-light-mode-border-color);
    --radio-disabled-state-normal-validity-unselected-value-dark-mode-background-color: var(--core-fill-40-dark-mode-color);
    --radio-disabled-state-normal-validity-unselected-value-dark-mode-border-color: var(--core-fill-40-dark-mode-color);
    --radio-disabled-state-normal-validity-unselected-value-light-mode-background-color: var(--core-fill-40-light-mode-color);
    --radio-disabled-state-normal-validity-unselected-value-light-mode-border-color: var(--core-fill-40-light-mode-color);
    --radio-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --radio-focus-ring-color: var(--radio-light-mode-focus-ring-color);
    --radio-focus-state-invalid-validity-selected-value-background-color: var(--radio-focus-state-invalid-validity-selected-value-light-mode-background-color);
    --radio-focus-state-invalid-validity-selected-value-border-color: var(--radio-focus-state-invalid-validity-selected-value-light-mode-border-color);
    --radio-focus-state-invalid-validity-selected-value-dark-mode-background-color: var(--radio-hover-state-invalid-validity-selected-value-dark-mode-background-color);
    --radio-focus-state-invalid-validity-selected-value-dark-mode-border-color: transparent;
    --radio-focus-state-invalid-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-focus-state-invalid-validity-selected-value-icon-color: var(--radio-focus-state-invalid-validity-selected-value-light-mode-icon-color);
    --radio-focus-state-invalid-validity-selected-value-light-mode-background-color: var(--radio-hover-state-invalid-validity-selected-value-light-mode-background-color);
    --radio-focus-state-invalid-validity-selected-value-light-mode-border-color: transparent;
    --radio-focus-state-invalid-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-focus-state-invalid-validity-unselected-value-background-color: var(--radio-focus-state-invalid-validity-unselected-value-light-mode-background-color);
    --radio-focus-state-invalid-validity-unselected-value-border-color: var(--radio-focus-state-invalid-validity-unselected-value-light-mode-border-color);
    --radio-focus-state-invalid-validity-unselected-value-dark-mode-background-color: var(--radio-hover-state-invalid-validity-unselected-value-dark-mode-background-color);
    --radio-focus-state-invalid-validity-unselected-value-dark-mode-border-color: var(--radio-hover-state-invalid-validity-unselected-value-dark-mode-border-color);
    --radio-focus-state-invalid-validity-unselected-value-light-mode-background-color: var(--radio-hover-state-invalid-validity-unselected-value-light-mode-background-color);
    --radio-focus-state-invalid-validity-unselected-value-light-mode-border-color: var(--radio-hover-state-invalid-validity-unselected-value-light-mode-border-color);
    --radio-focus-state-normal-validity-selected-value-background-color: var(--radio-focus-state-normal-validity-selected-value-light-mode-background-color);
    --radio-focus-state-normal-validity-selected-value-border-color: var(--radio-focus-state-normal-validity-selected-value-light-mode-border-color);
    --radio-focus-state-normal-validity-selected-value-dark-mode-background-color: var(--radio-hover-state-normal-validity-selected-value-dark-mode-background-color);
    --radio-focus-state-normal-validity-selected-value-dark-mode-border-color: transparent;
    --radio-focus-state-normal-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-focus-state-normal-validity-selected-value-icon-color: var(--radio-focus-state-normal-validity-selected-value-light-mode-icon-color);
    --radio-focus-state-normal-validity-selected-value-light-mode-background-color: var(--radio-hover-state-normal-validity-selected-value-light-mode-background-color);
    --radio-focus-state-normal-validity-selected-value-light-mode-border-color: transparent;
    --radio-focus-state-normal-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-focus-state-normal-validity-unselected-value-background-color: var(--radio-focus-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-focus-state-normal-validity-unselected-value-border-color: var(--radio-focus-state-normal-validity-unselected-value-light-mode-border-color);
    --radio-focus-state-normal-validity-unselected-value-dark-mode-background-color: var(--radio-hover-state-normal-validity-unselected-value-dark-mode-background-color);
    --radio-focus-state-normal-validity-unselected-value-dark-mode-border-color: var(--radio-hover-state-normal-validity-unselected-value-dark-mode-border-color);
    --radio-focus-state-normal-validity-unselected-value-light-mode-background-color: var(--radio-hover-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-focus-state-normal-validity-unselected-value-light-mode-border-color: var(--radio-hover-state-normal-validity-unselected-value-light-mode-border-color);
    --radio-height: 20px;
    --radio-hover-state-invalid-validity-selected-value-background-color: var(--radio-hover-state-invalid-validity-selected-value-light-mode-background-color);
    --radio-hover-state-invalid-validity-selected-value-border-color: var(--radio-hover-state-invalid-validity-selected-value-light-mode-border-color);
    --radio-hover-state-invalid-validity-selected-value-dark-mode-background-color: var(--core-critical-20-dark-mode-color);
    --radio-hover-state-invalid-validity-selected-value-dark-mode-border-color: transparent;
    --radio-hover-state-invalid-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-hover-state-invalid-validity-selected-value-icon-color: var(--radio-hover-state-invalid-validity-selected-value-light-mode-icon-color);
    --radio-hover-state-invalid-validity-selected-value-light-mode-background-color: var(--core-critical-20-light-mode-color);
    --radio-hover-state-invalid-validity-selected-value-light-mode-border-color: transparent;
    --radio-hover-state-invalid-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-hover-state-invalid-validity-unselected-value-background-color: var(--radio-hover-state-invalid-validity-unselected-value-light-mode-background-color);
    --radio-hover-state-invalid-validity-unselected-value-border-color: var(--radio-hover-state-invalid-validity-unselected-value-light-mode-border-color);
    --radio-hover-state-invalid-validity-unselected-value-dark-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-dark-mode-background-color);
    --radio-hover-state-invalid-validity-unselected-value-dark-mode-border-color: var(--core-critical-20-dark-mode-color);
    --radio-hover-state-invalid-validity-unselected-value-light-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-hover-state-invalid-validity-unselected-value-light-mode-border-color: var(--core-critical-20-light-mode-color);
    --radio-hover-state-normal-validity-selected-value-background-color: var(--radio-hover-state-normal-validity-selected-value-light-mode-background-color);
    --radio-hover-state-normal-validity-selected-value-border-color: var(--radio-hover-state-normal-validity-selected-value-light-mode-border-color);
    --radio-hover-state-normal-validity-selected-value-dark-mode-background-color: var(--core-emphasis-20-dark-mode-color);
    --radio-hover-state-normal-validity-selected-value-dark-mode-border-color: transparent;
    --radio-hover-state-normal-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-hover-state-normal-validity-selected-value-icon-color: var(--radio-hover-state-normal-validity-selected-value-light-mode-icon-color);
    --radio-hover-state-normal-validity-selected-value-light-mode-background-color: var(--core-emphasis-20-light-mode-color);
    --radio-hover-state-normal-validity-selected-value-light-mode-border-color: transparent;
    --radio-hover-state-normal-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-hover-state-normal-validity-unselected-value-background-color: var(--radio-hover-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-hover-state-normal-validity-unselected-value-border-color: var(--radio-hover-state-normal-validity-unselected-value-light-mode-border-color);
    --radio-hover-state-normal-validity-unselected-value-dark-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-dark-mode-background-color);
    --radio-hover-state-normal-validity-unselected-value-dark-mode-border-color: var(--core-emphasis-fill-dark-mode-color);
    --radio-hover-state-normal-validity-unselected-value-light-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-hover-state-normal-validity-unselected-value-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --radio-light-mode-focus-ring-color: var(--core-focus-ring-light-mode-color);
    --radio-normal-state-invalid-validity-selected-value-background-color: var(--radio-normal-state-invalid-validity-selected-value-light-mode-background-color);
    --radio-normal-state-invalid-validity-selected-value-border-color: var(--radio-normal-state-invalid-validity-selected-value-light-mode-border-color);
    --radio-normal-state-invalid-validity-selected-value-dark-mode-background-color: var(--core-critical-fill-dark-mode-color);
    --radio-normal-state-invalid-validity-selected-value-dark-mode-border-color: transparent;
    --radio-normal-state-invalid-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-normal-state-invalid-validity-selected-value-icon-color: var(--radio-normal-state-invalid-validity-selected-value-light-mode-icon-color);
    --radio-normal-state-invalid-validity-selected-value-light-mode-background-color: var(--core-critical-fill-light-mode-color);
    --radio-normal-state-invalid-validity-selected-value-light-mode-border-color: transparent;
    --radio-normal-state-invalid-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-normal-state-invalid-validity-unselected-value-background-color: var(--radio-normal-state-invalid-validity-unselected-value-light-mode-background-color);
    --radio-normal-state-invalid-validity-unselected-value-border-color: var(--radio-normal-state-invalid-validity-unselected-value-light-mode-border-color);
    --radio-normal-state-invalid-validity-unselected-value-dark-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-dark-mode-background-color);
    --radio-normal-state-invalid-validity-unselected-value-dark-mode-border-color: var(--core-critical-fill-dark-mode-color);
    --radio-normal-state-invalid-validity-unselected-value-light-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-normal-state-invalid-validity-unselected-value-light-mode-border-color: var(--core-critical-fill-light-mode-color);
    --radio-normal-state-normal-validity-selected-value-background-color: var(--radio-normal-state-normal-validity-selected-value-light-mode-background-color);
    --radio-normal-state-normal-validity-selected-value-border-color: var(--radio-normal-state-normal-validity-selected-value-light-mode-border-color);
    --radio-normal-state-normal-validity-selected-value-dark-mode-background-color: var(--core-emphasis-fill-dark-mode-color);
    --radio-normal-state-normal-validity-selected-value-dark-mode-border-color: transparent;
    --radio-normal-state-normal-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-normal-state-normal-validity-selected-value-icon-color: var(--radio-normal-state-normal-validity-selected-value-light-mode-icon-color);
    --radio-normal-state-normal-validity-selected-value-light-mode-background-color: var(--core-emphasis-fill-light-mode-color);
    --radio-normal-state-normal-validity-selected-value-light-mode-border-color: transparent;
    --radio-normal-state-normal-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-normal-state-normal-validity-unselected-value-background-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-normal-state-normal-validity-unselected-value-border-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-border-color);
    --radio-normal-state-normal-validity-unselected-value-dark-mode-background-color: transparent;
    --radio-normal-state-normal-validity-unselected-value-dark-mode-border-color: var(--core-fill-20-dark-mode-color);
    --radio-normal-state-normal-validity-unselected-value-light-mode-background-color: transparent;
    --radio-normal-state-normal-validity-unselected-value-light-mode-border-color: var(--core-fill-20-light-mode-color);
    --radio-pressed-state-invalid-validity-selected-value-background-color: var(--radio-pressed-state-invalid-validity-selected-value-light-mode-background-color);
    --radio-pressed-state-invalid-validity-selected-value-border-color: var(--radio-pressed-state-invalid-validity-selected-value-light-mode-border-color);
    --radio-pressed-state-invalid-validity-selected-value-dark-mode-background-color: var(--core-critical-10-dark-mode-color);
    --radio-pressed-state-invalid-validity-selected-value-dark-mode-border-color: transparent;
    --radio-pressed-state-invalid-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-pressed-state-invalid-validity-selected-value-icon-color: var(--radio-pressed-state-invalid-validity-selected-value-light-mode-icon-color);
    --radio-pressed-state-invalid-validity-selected-value-light-mode-background-color: var(--core-critical-10-light-mode-color);
    --radio-pressed-state-invalid-validity-selected-value-light-mode-border-color: transparent;
    --radio-pressed-state-invalid-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-pressed-state-invalid-validity-unselected-value-background-color: var(--radio-pressed-state-invalid-validity-unselected-value-light-mode-background-color);
    --radio-pressed-state-invalid-validity-unselected-value-border-color: var(--radio-pressed-state-invalid-validity-unselected-value-light-mode-border-color);
    --radio-pressed-state-invalid-validity-unselected-value-dark-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-dark-mode-background-color);
    --radio-pressed-state-invalid-validity-unselected-value-dark-mode-border-color: var(--core-critical-10-dark-mode-color);
    --radio-pressed-state-invalid-validity-unselected-value-light-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-pressed-state-invalid-validity-unselected-value-light-mode-border-color: var(--core-critical-10-light-mode-color);
    --radio-pressed-state-normal-validity-selected-value-background-color: var(--radio-pressed-state-normal-validity-selected-value-light-mode-background-color);
    --radio-pressed-state-normal-validity-selected-value-border-color: var(--radio-pressed-state-normal-validity-selected-value-light-mode-border-color);
    --radio-pressed-state-normal-validity-selected-value-dark-mode-background-color: var(--core-emphasis-10-dark-mode-color);
    --radio-pressed-state-normal-validity-selected-value-dark-mode-border-color: transparent;
    --radio-pressed-state-normal-validity-selected-value-dark-mode-icon-color: var(--core-fill-white-dark-mode-color);
    --radio-pressed-state-normal-validity-selected-value-icon-color: var(--radio-pressed-state-normal-validity-selected-value-light-mode-icon-color);
    --radio-pressed-state-normal-validity-selected-value-light-mode-background-color: var(--core-emphasis-10-light-mode-color);
    --radio-pressed-state-normal-validity-selected-value-light-mode-border-color: transparent;
    --radio-pressed-state-normal-validity-selected-value-light-mode-icon-color: var(--core-fill-white-light-mode-color);
    --radio-pressed-state-normal-validity-unselected-value-background-color: var(--radio-pressed-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-pressed-state-normal-validity-unselected-value-border-color: var(--radio-pressed-state-normal-validity-unselected-value-light-mode-border-color);
    --radio-pressed-state-normal-validity-unselected-value-dark-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-dark-mode-background-color);
    --radio-pressed-state-normal-validity-unselected-value-dark-mode-border-color: var(--core-emphasis-10-dark-mode-color);
    --radio-pressed-state-normal-validity-unselected-value-light-mode-background-color: var(--radio-normal-state-normal-validity-unselected-value-light-mode-background-color);
    --radio-pressed-state-normal-validity-unselected-value-light-mode-border-color: var(--core-emphasis-10-light-mode-color);
    --radio-svg-radius: 3px;
    --radio-width: 20px;
    --row-dark-mode-focus-ring-color: var(--core-focus-ring-dark-mode-color);
    --row-disabled-state-drill-color: var(--row-disabled-state-drill-light-mode-color);
    --row-disabled-state-drill-dark-mode-color: rgba(255,255,255,0.15);
    --row-disabled-state-drill-light-mode-color: rgba(0,0,0,0.15);
    --row-disabled-state-image-opacity: 0.2;
    --row-disabled-state-separator-color: var(--row-disabled-state-separator-light-mode-color);
    --row-disabled-state-separator-dark-mode-color: rgba(255,255,255,0.08);
    --row-disabled-state-separator-light-mode-color: rgba(0,0,0,0.05);
    --row-disabled-state-subtext-color: var(--row-disabled-state-subtext-light-mode-color);
    --row-disabled-state-subtext-dark-mode-color: rgba(255,255,255,0.3);
    --row-disabled-state-subtext-light-mode-color: rgba(0,0,0,0.3);
    --row-disabled-state-text-color: var(--row-disabled-state-text-light-mode-color);
    --row-disabled-state-text-dark-mode-color: rgba(255,255,255,0.3);
    --row-disabled-state-text-light-mode-color: rgba(0,0,0,0.3);
    --row-focus-ring-border-size: var(--core-focus-ring-border-size);
    --row-focus-ring-buffer-size: var(--core-focus-ring-buffer-size);
    --row-focus-ring-color: var(--row-light-mode-focus-ring-color);
    --row-focus-ring-radius: 6px;
    --row-focus-state-background-color: var(--row-focus-state-background-light-mode-color);
    --row-focus-state-background-dark-mode-color: var(--core-blue-40-dark-mode-color);
    --row-focus-state-background-light-mode-color: var(--core-blue-40-light-mode-color);
    --row-focus-state-drill-color: var(--row-focus-state-drill-light-mode-color);
    --row-focus-state-drill-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --row-focus-state-drill-light-mode-color: var(--core-blue-fill-light-mode-color);
    --row-focus-state-separator-color: var(--row-focus-state-separator-light-mode-color);
    --row-focus-state-separator-dark-mode-color: rgba(255,255,255,0.08);
    --row-focus-state-separator-light-mode-color: rgba(0,0,0,0.05);
    --row-focus-state-subtext-color: var(--row-focus-state-subtext-light-mode-color);
    --row-focus-state-subtext-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --row-focus-state-subtext-light-mode-color: var(--core-blue-text-light-mode-color);
    --row-focus-state-text-color: var(--row-focus-state-text-light-mode-color);
    --row-focus-state-text-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --row-focus-state-text-light-mode-color: var(--core-blue-text-light-mode-color);
    --row-horizontal-spacing: var(--row-small-layout-size-horizontal-spacing);
    --row-hover-state-background-color: var(--row-hover-state-background-light-mode-color);
    --row-hover-state-background-dark-mode-color: var(--core-blue-40-dark-mode-color);
    --row-hover-state-background-light-mode-color: var(--core-blue-40-light-mode-color);
    --row-hover-state-drill-color: var(--row-hover-state-drill-light-mode-color);
    --row-hover-state-drill-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --row-hover-state-drill-light-mode-color: var(--core-blue-fill-light-mode-color);
    --row-hover-state-separator-color: var(--row-hover-state-separator-light-mode-color);
    --row-hover-state-separator-dark-mode-color: rgba(255,255,255,0.08);
    --row-hover-state-separator-light-mode-color: rgba(0,0,0,0.05);
    --row-hover-state-subtext-color: var(--row-hover-state-subtext-light-mode-color);
    --row-hover-state-subtext-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --row-hover-state-subtext-light-mode-color: var(--core-blue-text-light-mode-color);
    --row-hover-state-text-color: var(--row-hover-state-text-light-mode-color);
    --row-hover-state-text-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --row-hover-state-text-light-mode-color: var(--core-blue-text-light-mode-color);
    --row-large-layout-size-horizontal-spacing: 16px;
    --row-large-layout-size-text-vertical-spacing: 2px;
    --row-large-layout-size-vertical-padding: 20px;
    --row-light-mode-focus-ring-color: var(--core-focus-ring-light-mode-color);
    --row-medium-layout-size-horizontal-spacing: 16px;
    --row-medium-layout-size-text-vertical-spacing: 2px;
    --row-medium-layout-size-vertical-padding: 20px;
    --row-normal-state-drill-color: var(--row-normal-state-drill-light-mode-color);
    --row-normal-state-drill-dark-mode-color: rgba(255,255,255,0.3);
    --row-normal-state-drill-light-mode-color: rgba(0,0,0,0.3);
    --row-normal-state-separator-color: var(--row-normal-state-separator-light-mode-color);
    --row-normal-state-separator-dark-mode-color: rgba(255,255,255,0.08);
    --row-normal-state-separator-light-mode-color: rgba(0,0,0,0.05);
    --row-normal-state-subtext-color: var(--row-normal-state-subtext-light-mode-color);
    --row-normal-state-subtext-dark-mode-color: rgba(255,255,255,0.55);
    --row-normal-state-subtext-light-mode-color: rgba(0,0,0,0.55);
    --row-normal-state-text-color: var(--row-normal-state-text-light-mode-color);
    --row-normal-state-text-dark-mode-color: rgba(255,255,255,0.95);
    --row-normal-state-text-light-mode-color: rgba(0,0,0,0.9);
    --row-pressed-state-background-color: var(--row-pressed-state-background-light-mode-color);
    --row-pressed-state-background-dark-mode-color: var(--core-blue-40-dark-mode-color);
    --row-pressed-state-background-light-mode-color: var(--core-blue-40-light-mode-color);
    --row-pressed-state-drill-color: var(--row-pressed-state-drill-light-mode-color);
    --row-pressed-state-drill-dark-mode-color: var(--core-blue-fill-dark-mode-color);
    --row-pressed-state-drill-light-mode-color: var(--core-blue-fill-light-mode-color);
    --row-pressed-state-separator-color: var(--row-pressed-state-separator-light-mode-color);
    --row-pressed-state-separator-dark-mode-color: rgba(255,255,255,0.08);
    --row-pressed-state-separator-light-mode-color: rgba(0,0,0,0.05);
    --row-pressed-state-subtext-color: var(--row-pressed-state-subtext-light-mode-color);
    --row-pressed-state-subtext-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --row-pressed-state-subtext-light-mode-color: var(--core-blue-text-light-mode-color);
    --row-pressed-state-text-color: var(--row-pressed-state-text-light-mode-color);
    --row-pressed-state-text-dark-mode-color: var(--core-blue-text-dark-mode-color);
    --row-pressed-state-text-light-mode-color: var(--core-blue-text-light-mode-color);
    --row-selected-state-background-color: var(--row-selected-state-background-light-mode-color);
    --row-selected-state-background-dark-mode-color: var(--core-blue-40-dark-mode-color);
    --row-selected-state-background-light-mode-color: var(--core-blue-40-light-mode-color);
    --row-selected-state-drill-color: var(--row-selected-state-drill-light-mode-color);
    --row-selected-state-drill-dark-mode-color: rgba(255,255,255,0.3);
    --row-selected-state-drill-light-mode-color: rgba(0,0,0,0.3);
    --row-selected-state-separator-color: var(--row-selected-state-separator-light-mode-color);
    --row-selected-state-separator-dark-mode-color: rgba(255,255,255,0.08);
    --row-selected-state-separator-light-mode-color: rgba(0,0,0,0.05);
    --row-selected-state-subtext-color: var(--row-selected-state-subtext-light-mode-color);
    --row-selected-state-subtext-dark-mode-color: rgba(255,255,255,0.55);
    --row-selected-state-subtext-light-mode-color: rgba(0,0,0,0.55);
    --row-selected-state-text-color: var(--row-selected-state-text-light-mode-color);
    --row-selected-state-text-dark-mode-color: rgba(255,255,255,0.95);
    --row-selected-state-text-light-mode-color: rgba(0,0,0,0.9);
    --row-small-layout-size-horizontal-spacing: 12px;
    --row-small-layout-size-text-vertical-spacing: 2px;
    --row-small-layout-size-vertical-padding: 16px;
    --row-subtext-case: regular;
    --row-subtext-large-layout-size-leading: var(--core-type-paragraph-20-medium-layout-size-leading);
    --row-subtext-large-layout-size-size: var(--core-type-paragraph-20-medium-layout-size-size);
    --row-subtext-large-layout-size-tracking: var(--core-type-paragraph-20-medium-layout-size-tracking);
    --row-subtext-leading: var(--row-subtext-small-layout-size-leading);
    --row-subtext-medium-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --row-subtext-medium-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --row-subtext-medium-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --row-subtext-scale: paragraph-20;
    --row-subtext-size: var(--row-subtext-small-layout-size-size);
    --row-subtext-small-layout-size-leading: 22px;
    --row-subtext-small-layout-size-size: 14px;
    --row-subtext-small-layout-size-tracking: 0;
    --row-subtext-tracking: var(--row-subtext-small-layout-size-tracking);
    --row-subtext-weight: var(--core-type-regular-weight);
    --row-text-case: regular;
    --row-text-large-layout-size-leading: var(--core-type-paragraph-30-medium-layout-size-leading);
    --row-text-large-layout-size-size: var(--core-type-paragraph-30-medium-layout-size-size);
    --row-text-large-layout-size-tracking: var(--core-type-paragraph-30-medium-layout-size-tracking);
    --row-text-leading: var(--row-text-small-layout-size-leading);
    --row-text-medium-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --row-text-medium-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --row-text-medium-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --row-text-scale: paragraph-30;
    --row-text-size: var(--row-text-small-layout-size-size);
    --row-text-small-layout-size-leading: 24px;
    --row-text-small-layout-size-size: 16px;
    --row-text-small-layout-size-tracking: 0;
    --row-text-tracking: var(--row-text-small-layout-size-tracking);
    --row-text-vertical-spacing: var(--row-small-layout-size-text-vertical-spacing);
    --row-text-weight: var(--core-type-regular-weight);
    --row-vertical-padding: var(--row-small-layout-size-vertical-padding);
    --segmented-control-animation-duration: 0.50s;
    --segmented-control-background-disabled-state-color: var(--segmented-control-background-disabled-state-light-mode-color);
    --segmented-control-background-disabled-state-dark-mode-color: var(--core-fill-40-dark-mode-color);
    --segmented-control-background-disabled-state-light-mode-color: var(--core-fill-40-light-mode-color);
    --segmented-control-background-large-layout-size-radius: 6px;
    --segmented-control-background-medium-layout-size-radius: 6px;
    --segmented-control-background-normal-state-color: var(--segmented-control-background-normal-state-light-mode-color);
    --segmented-control-background-normal-state-dark-mode-color: var(--core-fill-40-dark-mode-color);
    --segmented-control-background-normal-state-light-mode-color: var(--core-fill-40-light-mode-color);
    --segmented-control-background-radius: var(--segmented-control-background-small-layout-size-radius);
    --segmented-control-background-small-layout-size-radius: 6px;
    --segmented-control-large-layout-size-padding-horizontal: 4px;
    --segmented-control-large-layout-size-padding-vertical: 4px;
    --segmented-control-medium-layout-size-padding-horizontal: 4px;
    --segmented-control-medium-layout-size-padding-vertical: 4px;
    --segmented-control-option-background-disabled-state-color: var(--segmented-control-option-background-disabled-state-light-mode-color);
    --segmented-control-option-background-disabled-state-dark-mode-color: transparent;
    --segmented-control-option-background-disabled-state-light-mode-color: transparent;
    --segmented-control-option-background-hover-state-color: var(--segmented-control-option-background-hover-state-light-mode-color);
    --segmented-control-option-background-hover-state-dark-mode-color: var(--core-emphasis-40-dark-mode-color);
    --segmented-control-option-background-hover-state-light-mode-color: var(--core-emphasis-40-light-mode-color);
    --segmented-control-option-background-large-layout-size-radius: 4px;
    --segmented-control-option-background-medium-layout-size-radius: 4px;
    --segmented-control-option-background-normal-state-color: var(--segmented-control-option-background-normal-state-light-mode-color);
    --segmented-control-option-background-normal-state-dark-mode-color: transparent;
    --segmented-control-option-background-normal-state-light-mode-color: transparent;
    --segmented-control-option-background-pressed-state-color: var(--segmented-control-option-background-pressed-state-light-mode-color);
    --segmented-control-option-background-pressed-state-dark-mode-color: var(--core-emphasis-30-dark-mode-color);
    --segmented-control-option-background-pressed-state-light-mode-color: var(--core-emphasis-30-light-mode-color);
    --segmented-control-option-background-radius: var(--segmented-control-option-background-small-layout-size-radius);
    --segmented-control-option-background-selected-state-color: var(--segmented-control-option-background-selected-state-light-mode-color);
    --segmented-control-option-background-selected-state-dark-mode-color: var(--core-fill-30-dark-mode-color);
    --segmented-control-option-background-selected-state-light-mode-color: var(--core-fill-white-light-mode-color);
    --segmented-control-option-background-small-layout-size-radius: 4px;
    --segmented-control-option-label-case: regular;
    --segmented-control-option-label-disabled-state-color: var(--segmented-control-option-label-disabled-state-light-mode-color);
    --segmented-control-option-label-disabled-state-dark-mode-color: var(--core-text-30-dark-mode-color);
    --segmented-control-option-label-disabled-state-light-mode-color: var(--core-text-30-light-mode-color);
    --segmented-control-option-label-hover-state-color: var(--segmented-control-option-label-hover-state-light-mode-color);
    --segmented-control-option-label-hover-state-dark-mode-color: var(--core-text-10-dark-mode-color);
    --segmented-control-option-label-hover-state-light-mode-color: var(--core-text-10-light-mode-color);
    --segmented-control-option-label-large-layout-size-leading: var(--core-type-emphasis-30-medium-layout-size-leading);
    --segmented-control-option-label-large-layout-size-size: var(--core-type-emphasis-30-medium-layout-size-size);
    --segmented-control-option-label-large-layout-size-tracking: var(--core-type-emphasis-30-medium-layout-size-tracking);
    --segmented-control-option-label-leading: var(--segmented-control-option-label-small-layout-size-leading);
    --segmented-control-option-label-medium-layout-size-leading: var(--core-type-emphasis-30-small-layout-size-leading);
    --segmented-control-option-label-medium-layout-size-size: var(--core-type-emphasis-30-small-layout-size-size);
    --segmented-control-option-label-medium-layout-size-tracking: var(--core-type-emphasis-30-small-layout-size-tracking);
    --segmented-control-option-label-normal-state-color: var(--segmented-control-option-label-normal-state-light-mode-color);
    --segmented-control-option-label-normal-state-dark-mode-color: var(--core-text-10-dark-mode-color);
    --segmented-control-option-label-normal-state-light-mode-color: var(--core-text-10-light-mode-color);
    --segmented-control-option-label-pressed-state-color: var(--segmented-control-option-label-pressed-state-light-mode-color);
    --segmented-control-option-label-pressed-state-dark-mode-color: var(--core-text-10-dark-mode-color);
    --segmented-control-option-label-pressed-state-light-mode-color: var(--core-text-10-light-mode-color);
    --segmented-control-option-label-scale: paragraph-30;
    --segmented-control-option-label-selected-state-color: var(--segmented-control-option-label-selected-state-light-mode-color);
    --segmented-control-option-label-selected-state-dark-mode-color: var(--core-text-10-dark-mode-color);
    --segmented-control-option-label-selected-state-light-mode-color: var(--core-text-10-light-mode-color);
    --segmented-control-option-label-size: var(--segmented-control-option-label-small-layout-size-size);
    --segmented-control-option-label-small-layout-size-leading: var(--core-type-paragraph-30-small-layout-size-leading);
    --segmented-control-option-label-small-layout-size-size: var(--core-type-paragraph-30-small-layout-size-size);
    --segmented-control-option-label-small-layout-size-tracking: var(--core-type-paragraph-30-small-layout-size-tracking);
    --segmented-control-option-label-tracking: var(--segmented-control-option-label-small-layout-size-tracking);
    --segmented-control-option-label-weight: var(--core-type-medium-weight);
    --segmented-control-option-large-layout-size-padding-horizontal: 8px;
    --segmented-control-option-large-layout-size-padding-vertical: 8px;
    --segmented-control-option-medium-layout-size-padding-horizontal: 8px;
    --segmented-control-option-medium-layout-size-padding-vertical: 8px;
    --segmented-control-option-padding-horizontal: var(--segmented-control-option-small-layout-size-padding-horizontal);
    --segmented-control-option-padding-vertical: var(--segmented-control-option-small-layout-size-padding-vertical);
    --segmented-control-option-small-layout-size-padding-horizontal: 8px;
    --segmented-control-option-small-layout-size-padding-vertical: 8px;
    --segmented-control-padding-horizontal: var(--segmented-control-small-layout-size-padding-horizontal);
    --segmented-control-padding-vertical: var(--segmented-control-small-layout-size-padding-vertical);
    --segmented-control-small-layout-size-padding-horizontal: 4px;
    --segmented-control-small-layout-size-padding-vertical: 4px;
    --select-caret-color: var(--select-caret-light-mode-color);
    --select-caret-dark-mode-color: var(--core-fill-20-dark-mode-color);
    --select-caret-height: 8px;
    --select-caret-light-mode-color: var(--core-fill-20-light-mode-color);
    --textarea-font-case: regular;
    --textarea-font-large-layout-size-leading: var(--core-type-paragraph-20-medium-layout-size-leading);
    --textarea-font-large-layout-size-size: var(--core-type-paragraph-20-medium-layout-size-size);
    --textarea-font-large-layout-size-tracking: var(--core-type-paragraph-20-medium-layout-size-tracking);
    --textarea-font-leading: var(--textarea-font-small-layout-size-leading);
    --textarea-font-medium-layout-size-leading: var(--core-type-paragraph-20-small-layout-size-leading);
    --textarea-font-medium-layout-size-size: var(--core-type-paragraph-20-small-layout-size-size);
    --textarea-font-medium-layout-size-tracking: var(--core-type-paragraph-20-small-layout-size-tracking);
    --textarea-font-scale: paragraph-20;
    --textarea-font-size: var(--textarea-font-small-layout-size-size);
    --textarea-font-small-layout-size-leading: 22px;
    --textarea-font-small-layout-size-size: 14px;
    --textarea-font-small-layout-size-tracking: 0;
    --textarea-font-tracking: var(--textarea-font-small-layout-size-tracking);
    --textarea-font-weight: var(--core-type-regular-weight);
    --textarea-height: 112px;
    --textarea-min-height: var(--textarea-minimum-height);
    --textarea-minimum-height: var(--textarea-height);
    --textarea-placeholder-color: var(--textarea-placeholder-light-mode-color);
    --textarea-placeholder-dark-mode-color: var(--core-fill-30-dark-mode-color);
    --textarea-placeholder-light-mode-color: var(--core-fill-30-light-mode-color);
    --toggle-border-radius: var(--toggle-small-layout-size-border-radius);
    --toggle-border-size: var(--toggle-small-layout-size-border-size);
    --toggle-disabled-state-selected-value-border-color: var(--toggle-disabled-state-selected-value-light-mode-border-color);
    --toggle-disabled-state-selected-value-dark-mode-border-color: transparent;
    --toggle-disabled-state-selected-value-dark-mode-thumb-color: var(--core-fill-white-dark-mode-color);
    --toggle-disabled-state-selected-value-dark-mode-track-color: var(--core-fill-30-light-mode-color);
    --toggle-disabled-state-selected-value-light-mode-border-color: transparent;
    --toggle-disabled-state-selected-value-light-mode-thumb-color: var(--core-fill-white-light-mode-color);
    --toggle-disabled-state-selected-value-light-mode-track-color: var(--core-fill-30-light-mode-color);
    --toggle-disabled-state-selected-value-thumb-color: var(--toggle-disabled-state-selected-value-light-mode-thumb-color);
    --toggle-disabled-state-selected-value-track-color: var(--toggle-disabled-state-selected-value-light-mode-track-color);
    --toggle-disabled-state-unselected-value-border-color: var(--toggle-disabled-state-unselected-value-light-mode-border-color);
    --toggle-disabled-state-unselected-value-dark-mode-border-color: var(--core-fill-40-dark-mode-color);
    --toggle-disabled-state-unselected-value-dark-mode-thumb-color: var(--core-fill-30-dark-mode-color);
    --toggle-disabled-state-unselected-value-dark-mode-track-color: var(--core-fill-40-dark-mode-color);
    --toggle-disabled-state-unselected-value-light-mode-border-color: var(--core-fill-40-light-mode-color);
    --toggle-disabled-state-unselected-value-light-mode-thumb-color: var(--core-fill-30-light-mode-color);
    --toggle-disabled-state-unselected-value-light-mode-track-color: var(--core-fill-40-light-mode-color);
    --toggle-disabled-state-unselected-value-thumb-color: var(--toggle-disabled-state-unselected-value-light-mode-thumb-color);
    --toggle-disabled-state-unselected-value-track-color: var(--toggle-disabled-state-unselected-value-light-mode-track-color);
    --toggle-height: var(--toggle-small-layout-size-height);
    --toggle-hover-state-selected-value-border-color: var(--toggle-hover-state-selected-value-light-mode-border-color);
    --toggle-hover-state-selected-value-dark-mode-border-color: var(--core-emphasis-20-dark-mode-color);
    --toggle-hover-state-selected-value-dark-mode-thumb-color: var(--core-fill-white-dark-mode-color);
    --toggle-hover-state-selected-value-dark-mode-track-color: var(--core-emphasis-20-dark-mode-color);
    --toggle-hover-state-selected-value-light-mode-border-color: var(--core-emphasis-20-light-mode-color);
    --toggle-hover-state-selected-value-light-mode-thumb-color: var(--core-fill-white-light-mode-color);
    --toggle-hover-state-selected-value-light-mode-track-color: var(--core-emphasis-20-light-mode-color);
    --toggle-hover-state-selected-value-thumb-color: var(--toggle-hover-state-selected-value-light-mode-thumb-color);
    --toggle-hover-state-selected-value-track-color: var(--toggle-hover-state-selected-value-light-mode-track-color);
    --toggle-hover-state-unselected-value-border-color: var(--toggle-hover-state-unselected-value-light-mode-border-color);
    --toggle-hover-state-unselected-value-dark-mode-border-color: var(--core-emphasis-fill-dark-mode-color);
    --toggle-hover-state-unselected-value-dark-mode-thumb-color: var(--core-emphasis-fill-dark-mode-color);
    --toggle-hover-state-unselected-value-dark-mode-track-color: transparent;
    --toggle-hover-state-unselected-value-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --toggle-hover-state-unselected-value-light-mode-thumb-color: var(--core-emphasis-fill-light-mode-color);
    --toggle-hover-state-unselected-value-light-mode-track-color: transparent;
    --toggle-hover-state-unselected-value-thumb-color: var(--toggle-hover-state-unselected-value-light-mode-thumb-color);
    --toggle-hover-state-unselected-value-track-color: var(--toggle-hover-state-unselected-value-light-mode-track-color);
    --toggle-large-layout-size-border-radius: 100px;
    --toggle-large-layout-size-border-size: 2px;
    --toggle-large-layout-size-height: 24px;
    --toggle-large-layout-size-padding-horizontal: 4px;
    --toggle-large-layout-size-padding-vertical: 6px;
    --toggle-large-layout-size-width: 40px;
    --toggle-medium-layout-size-border-radius: 100px;
    --toggle-medium-layout-size-border-size: 2px;
    --toggle-medium-layout-size-height: 24px;
    --toggle-medium-layout-size-padding-horizontal: 4px;
    --toggle-medium-layout-size-padding-vertical: 6px;
    --toggle-medium-layout-size-width: 40px;
    --toggle-normal-state-selected-value-border-color: var(--toggle-normal-state-selected-value-light-mode-border-color);
    --toggle-normal-state-selected-value-dark-mode-border-color: var(--core-emphasis-fill-dark-mode-color);
    --toggle-normal-state-selected-value-dark-mode-thumb-color: var(--core-fill-white-dark-mode-color);
    --toggle-normal-state-selected-value-dark-mode-track-color: var(--core-emphasis-fill-dark-mode-color);
    --toggle-normal-state-selected-value-light-mode-border-color: var(--core-emphasis-fill-light-mode-color);
    --toggle-normal-state-selected-value-light-mode-thumb-color: var(--core-fill-white-light-mode-color);
    --toggle-normal-state-selected-value-light-mode-track-color: var(--core-emphasis-fill-light-mode-color);
    --toggle-normal-state-selected-value-thumb-color: var(--toggle-normal-state-selected-value-light-mode-thumb-color);
    --toggle-normal-state-selected-value-track-color: var(--toggle-normal-state-selected-value-light-mode-track-color);
    --toggle-normal-state-unselected-value-border-color: var(--toggle-normal-state-unselected-value-light-mode-border-color);
    --toggle-normal-state-unselected-value-dark-mode-border-color: var(--core-fill-20-dark-mode-color);
    --toggle-normal-state-unselected-value-dark-mode-thumb-color: var(--core-fill-20-dark-mode-color);
    --toggle-normal-state-unselected-value-dark-mode-track-color: transparent;
    --toggle-normal-state-unselected-value-light-mode-border-color: var(--core-fill-20-light-mode-color);
    --toggle-normal-state-unselected-value-light-mode-thumb-color: var(--core-fill-20-light-mode-color);
    --toggle-normal-state-unselected-value-light-mode-track-color: transparent;
    --toggle-normal-state-unselected-value-thumb-color: var(--toggle-normal-state-unselected-value-light-mode-thumb-color);
    --toggle-normal-state-unselected-value-track-color: var(--toggle-normal-state-unselected-value-light-mode-track-color);
    --toggle-padding-horizontal: var(--toggle-small-layout-size-padding-horizontal);
    --toggle-padding-vertical: var(--toggle-small-layout-size-padding-vertical);
    --toggle-pressed-state-selected-value-border-color: var(--toggle-pressed-state-selected-value-light-mode-border-color);
    --toggle-pressed-state-selected-value-dark-mode-border-color: var(--core-emphasis-10-dark-mode-color);
    --toggle-pressed-state-selected-value-dark-mode-thumb-color: var(--core-fill-white-dark-mode-color);
    --toggle-pressed-state-selected-value-dark-mode-track-color: var(--core-emphasis-10-dark-mode-color);
    --toggle-pressed-state-selected-value-light-mode-border-color: var(--core-emphasis-10-light-mode-color);
    --toggle-pressed-state-selected-value-light-mode-thumb-color: var(--core-fill-white-light-mode-color);
    --toggle-pressed-state-selected-value-light-mode-track-color: var(--core-emphasis-10-light-mode-color);
    --toggle-pressed-state-selected-value-thumb-color: var(--toggle-pressed-state-selected-value-light-mode-thumb-color);
    --toggle-pressed-state-selected-value-track-color: var(--toggle-pressed-state-selected-value-light-mode-track-color);
    --toggle-pressed-state-unselected-value-border-color: var(--toggle-pressed-state-unselected-value-light-mode-border-color);
    --toggle-pressed-state-unselected-value-dark-mode-border-color: var(--core-emphasis-10-dark-mode-color);
    --toggle-pressed-state-unselected-value-dark-mode-thumb-color: var(--core-emphasis-10-dark-mode-color);
    --toggle-pressed-state-unselected-value-dark-mode-track-color: transparent;
    --toggle-pressed-state-unselected-value-light-mode-border-color: var(--core-emphasis-10-light-mode-color);
    --toggle-pressed-state-unselected-value-light-mode-thumb-color: var(--core-emphasis-10-light-mode-color);
    --toggle-pressed-state-unselected-value-light-mode-track-color: transparent;
    --toggle-pressed-state-unselected-value-thumb-color: var(--toggle-pressed-state-unselected-value-light-mode-thumb-color);
    --toggle-pressed-state-unselected-value-track-color: var(--toggle-pressed-state-unselected-value-light-mode-track-color);
    --toggle-small-layout-size-border-radius: 100px;
    --toggle-small-layout-size-border-size: 2px;
    --toggle-small-layout-size-height: 24px;
    --toggle-small-layout-size-padding-horizontal: 4px;
    --toggle-small-layout-size-padding-vertical: 6px;
    --toggle-small-layout-size-width: 40px;
    --toggle-width: var(--toggle-small-layout-size-width);
    --modal-dialog-animation: var(--modal-dialog-animation-duration);
    --modal-partial-medium-viewport-width: 512px;
    --modal-partial-wide-viewport-width: 512px;
    --core-grid-column-count: var(--core-extra-wide-viewport-grid-column-count);
    --core-grid-horizontal-spacing: var(--core-extra-wide-viewport-grid-horizontal-spacing);
    --core-grid-vertical-spacing: var(--core-extra-wide-viewport-grid-vertical-spacing);
    --core-grid-item-full-size-column-span: var(--core-extra-wide-viewport-grid-item-full-size-column-span);
    --core-grid-item-large-size-column-span: var(--core-extra-wide-viewport-grid-item-large-size-column-span);
    --core-grid-item-medium-size-column-span: var(--core-extra-wide-viewport-grid-item-medium-size-column-span);
    --core-grid-item-small-size-column-span: var(--core-extra-wide-viewport-grid-item-small-size-column-span);
    --modal-partial-vertical-padding-size: var(--modal-partial-wide-viewport-vertical-padding-size);
    --modal-partial-vertical-screen-buffer-size: var(--modal-partial-wide-viewport-vertical-screen-buffer-size);
    --modal-partial-horizontal-padding-size: var(--modal-partial-wide-viewport-horizontal-padding-size);
    --modal-partial-horizontal-screen-buffer-size: var(--modal-partial-wide-viewport-horizontal-screen-buffer-size);
    --modal-partial-width: var(--modal-partial-wide-viewport-width);
    --modal-partial-animation-duration: var(--modal-partial-medium-viewport-animation-duration);
    --modal-full-vertical-padding-size: var(--modal-full-medium-viewport-vertical-padding-size);
    --modal-full-horizontal-padding-size: var(--modal-full-medium-viewport-horizontal-padding-size);
    --modal-full-animation-duration: var(--modal-full-medium-viewport-animation-duration);
    --modal-dialog-vertical-padding-size: var(--modal-dialog-medium-viewport-vertical-padding-size);
    --modal-dialog-horizontal-padding-size: var(--modal-dialog-medium-viewport-horizontal-padding-size);
    --modal-dialog-width: var(--modal-dialog-medium-viewport-width);
    color: #1a1a1a;
    -webkit-font-smoothing: antialiased;
    font: 14px/1.5714285714 "Square Market","helvetica neue",helvetica,arial,sans-serif;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    border-collapse: collapse;
    border-spacing: 0;
    width: 50%;
    table-layout: fixed;
    font-feature-settings: "tnum" 1;
}

.rqsLogoDiv {
    align-content: left; 
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
}

.rqs radio {
    padding-right: 10px;
}

.rqsSquareLogo {
    width:auto;
    height:50px;
	border-radius: 10px;
}

.rqsImg {
    height: 100%;
    width: 100%;

}

.rqs li {
     list-style: none;
}

.rqs li label {
    padding-left:10px;
}

/* Red */
 @media all and (max-width: 450px) {
     :root {
         --fontsize-nav:1.1rem;
         --typing-font:2rem;
    }
     .App {
         flex-direction: column;
    }
     .sidebar {
         padding: 30px;
         background: #B7E4C7;
         background: linear-gradient(180deg, #B7E4C7 50%,#2D6A4F 80%);
    }
     .condiv {
         padding: 50px ;
    }
     .headerSection ul {
         display: flex;
    }
     .headerSection ul li {
         margin: 10px;
    }
     .footerSection ul {
         display: flex;
    }
     .footerSection ul li {
         margin: 5px;
         font-size:12px 
    }
}

@media only screen and (max-width: 768px) {
    .rqsInputContainer {
        align-content: center !important;
        text-align: center !important;
        width: 100%;
    }
  }
